/**
 * Parsing function that takes a line string coming from Import Variables file and
 * returns an array with 2 values (key-value pair):
 * - item in position 0 is the key
 * - item in position 1 is the value
 * @param {string} s line that needs to be parsed
 */
export const parseKeyValue = (s: string): [string, string] => {
  const index = s.indexOf(" ");
  return [s.substring(0, index).trim(), s.substring(index).trim()];
};
