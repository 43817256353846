import styled from "styled-components";

export const mainIcon = `${process.env.PUBLIC_URL}/assets/crown-alert-main-icon.svg`;

export const MainIcon = styled.div<{ iconURL: string }>`
  margin: 40px 0 54px 0;
  width: 200px;
  height: 162px;
  background-image: ${(props) => `url(${props.iconURL})`};
  background-size: 200px;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
`;

export const Text = styled.div`
  margin-top: 16px;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.DARK[400]};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonsContainer = styled.div`
  margin-bottom: 40px;
  width: 380px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  @media (max-width: 768px) {
    width: 80%;
  }
`;

export const Button = styled.button`
  cursor: pointer;
  width: 130px;
  height: 60px;
  align-items: center;
  justify-content: center;
  background-color: #4a82a6;
  border: solid 1px #4a82a6;
  border-radius: 6px;
  color: #fff;
  @media (max-width: 768px) {
    width: 120px;
    height: 50px;
  }
`;
