import { FieldName } from "styles/SectionSharedItems.styled";
import { Button } from "styles/admin/Dashboard.styled";
import { DocumentsDiv, PaperClipDiv } from "styles/funds/index.styled";
import { IconButton } from "@material-ui/core";
import { AttachFile } from "@material-ui/icons";
import { CcIdListProps } from "types";

export function Documents({
  data
}: {
  data: CcIdListProps
}) {
  return (
    <DocumentsDiv>
      <FieldName>Documents</FieldName>
      <PaperClipDiv>
        <IconButton disabled>
          <AttachFile />
        </IconButton>
        <span data-testid="no-of-documents">{data.documents}</span>
      </PaperClipDiv>
      {/* TODO: add click handler */}
      <Button type="button">View all</Button>
    </DocumentsDiv>
  );
}
