import { SelectBox } from "components/alert/airTable/SelectBox";
import { useEffect, useState, useRef } from "react";
import { FieldName } from "styles/SectionSharedItems.styled";
import { RiskRankingDiv, RiskRankingOption } from "styles/funds/index.styled";
import { SelectBoxDetails } from "types";
import { CcIdListProps } from "types";

export function RiskRanking({
  data
}: {
  data: CcIdListProps
}) {
  const [option, setOption] = useState<string>("Loading");
  const optionRef = useRef(option);

  const selectBoxOptions: SelectBoxDetails = {
    selectBoxName: "risk-ranking-select",
    selectListOfOptions: [
      {
        optionValue: "Low",
        optionContent: "Low",
      },
      {
        optionValue: "Medium",
        optionContent: "Medium",
      },
      {
        optionValue: "High",
        optionContent: "High",
      },
    ],
  };

  useEffect(() => {
    const optionDiv = document.querySelector(".risk-ranking-op") as HTMLDivElement;
    if (optionDiv) {
      if (option === "Low") {
        optionDiv.style.color = "Green";
      } else if (option === "Medium") {
        optionDiv.style.color = "#c98608";
      } else if (option === "High") {
        optionDiv.style.color = "red";
        optionDiv.style.fontWeight = "700";
      } else {
        optionDiv.style.color = "#364073";
      }
    }
  }, [option]);
  
  // TODO: further adjustments to risk ranking will be needed
  useEffect(() => {
    if (data.documents > 8 && data.documents < 19) {
      setOption("Medium");
      optionRef.current = "Medium";
    } else if (data.documents < 46 && data.documents > 19) {
      setOption("Low");
      optionRef.current = "Low";
    } else if (data.documents < 8) {
      setOption("High");
      optionRef.current = "High";
    }
  }, [data]);

  return (
    <RiskRankingDiv>
      <FieldName>Risk Ranking</FieldName>
      <RiskRankingOption data-testid="risk-ranking-op" className="risk-ranking-op">
        {option}
      </RiskRankingOption>
      <SelectBox
        selectBoxDetails={selectBoxOptions}
        stateSetter={setOption}
        defaultOptionHidden="Change"
      />
    </RiskRankingDiv>
  );
}
