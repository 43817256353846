import styled from "styled-components";

export const CcIdCardDashboardContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 20px;
    flex: 70%;
`;

export const CcIdDashboardCard = styled.div`
    width: 580px;
    height: 300px;
    padding: 20px 20px;
    overflow-y: scroll;
    border-radius: 20px;
    border: 4px solid grey;
    &:hover {
    border: 4px solid #2e1a61;
    }
    scrollbar-width: none;    
`;