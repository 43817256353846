// Copyright Marco Rapaccini, Marcello Di Fronzo Gravallese and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.

/**
 * This is the dedicated component for the Search Suggestions.
 */

import { useEffect, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { useToast } from "hooks/useToast";
import { LUCENE_GCF, CALL_NEO4J_EXTERNALLY } from "../../config/googleCloudFunctionsConfig";
import {
  CallNeo4jExternallyItem,
  SearchSuggestionInput,
  SearchSuggestionResult,
  RelatedEntity,
} from "../../types";
import { SearchSuggestionItem } from "./SearchSuggestionItem";
import {
  TopSearches,
  Loader,
  LoaderBar,
  Suggestions,
  SuggestionNotFound,
} from "../../styles/SearchSuggestion.styled";

const searchSuggestionsLimit: number = 100;

export function SearchSuggestions({
  searchString,
  isIdentifierSearch,
  showTopSearches = true,
  noMargin = false,
}: {
  searchString: string;
  isIdentifierSearch: boolean;
  showTopSearches?: boolean;
  noMargin?: boolean;
}) {
  const { toast } = useToast();
  const { authState, oktaAuth } = useOktaAuth();

  const [resultsToDisplay, setResultsToDisplay] = useState<SearchSuggestionResult[]>([]);

  const [luceneReplyLength, setLuceneReplyLength] = useState<number | null>(null);

  const [searchSuggestionsHasFinished, setSearchSuggestionsHasFinished] = useState<boolean>(false);

  // this is the asynchronous function for retrieving data from Lucene
  const fetchResults = async () => {
    if (authState && authState.isAuthenticated) {
      const accessToken = oktaAuth.getAccessToken();

      if (accessToken) {
        /*
         * Here we prepare all what we need for the Google Function call
         */
        const paramsObj = {
          accessToken,
          stringToMatch: searchString,
          isIdentifierSearch: isIdentifierSearch.toString(),
        };

        const searchParams = new URLSearchParams(paramsObj);

        const googleFunctionApiUrlForLucene = `${LUCENE_GCF}?${searchParams
          .toString()
          .replace(/%2f/gi, "/")}`;

        // then we do the fetch
        await fetch(googleFunctionApiUrlForLucene)
          .then((response) => {
            if (!response.ok) {
              throw new Error("SearchSuggestions - Error when calling Google Function --> Lucene");
            }
            return response;
          })
          .then((response: Response) => response.json())
          .then((luceneResults) => {
            const guosArray: RelatedEntity[] = [];
            const nonGuosArray: RelatedEntity[] = [];
            const luceneNonNeo4jArray: RelatedEntity[] = [];

            const luceneResultsLength = luceneResults.length;
            setLuceneReplyLength(luceneResultsLength);

            if (luceneResultsLength > 0) {
              const entityIdList = luceneResults.map((item: SearchSuggestionInput) => {
                return item.id;
              });
              const payloadForGCF = {
                accessToken,
                entity_id_list: entityIdList,
                query_type: "search_suggestions_list",
              };
              const options = {
                method: "POST",
                headers: { Accept: "application/json", "Content-Type": "application/json" },
                body: JSON.stringify(payloadForGCF),
              };

              fetch(CALL_NEO4J_EXTERNALLY, options)
                .then((response: Response) => response.json())
                .then((response: CallNeo4jExternallyItem[]) => {
                  response.forEach((item: CallNeo4jExternallyItem, index: number) => {
                    // the response array is in the same order of the luceneResults array
                    const relatedEntityItem: RelatedEntity = {
                      name:
                        luceneResults[index].name || luceneResults[index].internationalName || "",
                      isGuo: item.status === "guo",
                      id: luceneResults[index].id,
                      iso: luceneResults[index].isoCountryCode,
                      type: luceneResults[index].type,
                      notInNeo: item.status === "null",
                    };

                    // this an alternative to a switch or to an if/else/else-if
                    const assignToRightArray: { [key: string]: RelatedEntity[] } = {
                      guo: guosArray,
                      sub: nonGuosArray,
                      null: luceneNonNeo4jArray,
                    };
                    assignToRightArray[item.status].push(relatedEntityItem);
                  });
                })
                .finally(() =>
                  setResultsToDisplay(guosArray.concat(nonGuosArray).concat(luceneNonNeo4jArray)),
                )
                .catch((err: Error) => toast(err.toString()));
            }
          })
          .catch((err: Error) => toast(err.toString()));
      }
    }
  };

  const resetValues = () => {
    setResultsToDisplay([]);
    setLuceneReplyLength(null);
    setSearchSuggestionsHasFinished(false);
  };

  useEffect(() => {
    if (searchString.trim() !== "") {
      resetValues();
      fetchResults();
    }
  }, [searchString]);

  function SearchSuggestionItemsList() {
    // sometimes the results are less than the searchSuggestionsLimit, so we need to pick the smallest one between the 2
    const maxSuggestionIndex =
      (resultsToDisplay.length < searchSuggestionsLimit
        ? resultsToDisplay.length
        : searchSuggestionsLimit) - 1;
    return resultsToDisplay.length === luceneReplyLength
      ? resultsToDisplay.map((item: RelatedEntity, index) =>
          index < searchSuggestionsLimit ? (
            <SearchSuggestionItem
              key={index}
              entityToDisplay={item}
              setSearchSuggestionsHasFinished={setSearchSuggestionsHasFinished}
              isLast={index === maxSuggestionIndex}
              isIdentifierSearch={isIdentifierSearch}
            />
          ) : null,
        )
      : null;
  }

  return (
    <Suggestions noMargin={noMargin}>
      {showTopSearches && (
        <TopSearches data-testid="top-searches">
          <p>Top Searches</p>
        </TopSearches>
      )}
      {!searchSuggestionsHasFinished && luceneReplyLength !== 0 && (
        <Loader data-testid="loader">
          <LoaderBar />
        </Loader>
      )}
      {luceneReplyLength !== 0 ? (
        SearchSuggestionItemsList()
      ) : (
        <SuggestionNotFound data-testid="suggestion-not-found">
          Nothing found, try a different search
        </SuggestionNotFound>
      )}
    </Suggestions>
  );
}
