export const PARTY_TABS = ["Profile", "FUNDS", "AFME", "CASH", "Payments"] as PARTY_TAB[];

export enum PARTY_TAB {
  DEFAULT = "",
  PROFILE = "Profile",
  FUNDS = "FUNDS",
  AFME = "AFME",
  CASH = "CASH",
  T360_PAYMENTS = "Payments",
}

export const groupD = ["Group Name", "Entity Group Name", "Entity Name"];

export const FundsInfoD = [
  "Currency",
  "ISIN",
  "Citicode",
  "SEDOL",
  "Fund Type",
  "Asset Class Title",
];

export const PerformanceD = [
  "Price",
  "Yield",
  "Ratio Alpha",
  "Ratio Beta",
  "Ratio Volatility",
  "Ratio Sharpe",
  "Risk Score",
  "Morning Star Analyst Rating",
  "Discrete Performance 0 to 12 Months",
  "Discrete Performance 12 to 24 Months",
  "Discrete Performance 24 to 36 Months",
  "Annual Performance 12 Months",
  "Annual Performance 12 to 24 Months",
  "Annual Performance 24 to 36 Months",
  "Annual Performance 36 to 48 Months",
  "Annual Performance 48 to 60 Months",
  "Annual Performance YTD",
];

export const FundsD = [
  "Fund Name",
  "Currency",
  "ISIN",
  "Citicode",
  "Legal Entity Identifier",
  "SEDOL",
  "Fund Type",
  "Asset Class Title",
  "Portfolio Sector",
  "Portfolio Asset Class",
  "Fund ID",
  "Child Fund ID",
];

export interface TableField<T> {
  name: keyof T;
  displayName?: string;
}

export interface TableData<T> {
  items: T[];
}

export type FieldBuilder<T, D> = (
  field: TableField<T>,
  data: D,
  row: number,
  column: number,
) => React.ReactNode;

export interface TableProps<T, D> {
  fields: TableField<T>[];
  tableData: D[];
  noDataMessage?: string;
  builder: FieldBuilder<T, D>;
  isLoading?: boolean;
  startColumn?: number;
  startRow?: number;
  columns?: number;
  rows?: number;
  before?: React.ReactNode;
  after?: React.ReactNode;
  onClickRow?: (id: string) => void;
  idKey?: string;
}
