import { FieldName } from "styles/SectionSharedItems.styled";
import {
  Circle,
  CircleBar,
  CircleProgress,
  CircleText,
  ProgressWdgtDiv,
} from "styles/funds/index.styled";
import { useEffect } from "react";
import { CcIdListProps } from "types";

export function ProgressWdgt({
  data
}: {
  data: CcIdListProps
}) {
  function setCircleProgress(value: number) {
    const circleBar = document.querySelector(".circle-bar") as SVGElement;
    const circleText = document.querySelector(".circle-text");

    if (circleBar && circleText) {
      const radius = circleBar.getAttribute("r");
      if (radius) {
        const circumference = 2 * Math.PI * parseInt(radius, 10);
        const offset = circumference - (value / 100) * circumference;
        circleBar.style.strokeDashoffset = offset.toString();
        circleText.textContent = `${value}%`;
      }
    }
  }

  // TODO: dynamic progress updating might be altered in the future

  useEffect(() => {
    switch (data.status) {
      case "Information gathering":
        setCircleProgress(40);
        break;
      case "Information checking":
        setCircleProgress(80);
        break;
      default:
        setCircleProgress(0);
    }
  }, [data]);

  return (
    <ProgressWdgtDiv>
      <FieldName>Progress</FieldName>
      <CircleProgress>
        <Circle>
          <CircleBar data-testid="circle-bar" className="circle-bar" r="40" cx="50" cy="50" />
        </Circle>
        <CircleText data-testid="circle-text" className="circle-text">
          0%
        </CircleText>
      </CircleProgress>
    </ProgressWdgtDiv>
  );
}
