import { Link, useLocation } from "react-router-dom";
import { MouseEventHandler, useState } from "react";
import {
  StyledHeader,
  StyledHeaderLeft,
  StyledHeaderRight,
  StyledHeaderTitle,
  StyledLogo,
  StyledMenuIcon,
  IconsContainer,
  IconsSeparator,
  searchIconWhite,
  alertIconWhite,
  notificationIconWhite,
  ProfileIconWrapper,
  hubwiseLogo
} from "styles/header/Header.styled";
import { PATH } from "constants/index";
import { ActivityType, useTrackActivity } from "hooks/useTracker";
import Tooltip from "components/Tooltip";
import { TRACKER } from "constants/tracker";
import NotificationTab from "../notificationTab/NotificationTab";
import CrownAlert from "../crownAlert/CrownAlert";
import UserProfileHeader from "./UserProfileHeader";
import { HeaderStyle } from "../../types";
import FundSideMenu from "./FundSideMenu";

function FundHeader({
  headerStyle,
  isUserAuthenticated,
}: {
  headerStyle: HeaderStyle;
  isUserAuthenticated: boolean;
}) {
  const { pathname } = useLocation();
  const NOT_FUND =
    pathname.includes(PATH.ADMIN) ||
    pathname.includes(PATH.CLIENT) ||
    pathname.includes(PATH.DIDO_DEMO);

  // show or hide side menu
  const [showSideMenu, setShowSideMenu] = useState(false);

  // for crowd alert
  const [alertIsActive, setAlertIsActive] = useState(false);
  const menuToggle = () => setAlertIsActive(!alertIsActive);

  // for notification tab
  const [tabIsActive, setTabIsActive] = useState(false);
  const tabToggle = () => setTabIsActive(!tabIsActive);

  const { track } = useTrackActivity();

  function handleTrackClick(button: string): MouseEventHandler<HTMLAnchorElement | HTMLDivElement> {
    return () => {
      track(ActivityType.BUTTON_CLICK, `${button} page`, {
        button_clicked: `${button} Button`,
        clicked_from: TRACKER.NAVIGATION_HEADER,
      });
    };
  }

  if (NOT_FUND) return null;
  return (
    <StyledHeader headerStyle={headerStyle} className="container">
      <FundSideMenu show={showSideMenu} close={() => setShowSideMenu(false)} />
      <StyledHeaderLeft>
        <StyledMenuIcon fontSize="large" onClick={() => setShowSideMenu(true)} />
        <Link to={!alertIsActive ? PATH.HUBWISE_DASHBOARD : pathname}>
          <StyledLogo headerStyle={headerStyle} iconURL={hubwiseLogo} />
          <StyledHeaderTitle headerStyle={headerStyle}>SS&C FUND PARTY MANAGER</StyledHeaderTitle>
        </Link>
      </StyledHeaderLeft>
      {isUserAuthenticated && (
        <StyledHeaderRight>
          <Tooltip content="search">
            <IconsSeparator>
              <Link
                to={!alertIsActive ? PATH.FUND_SEARCH : pathname}
                onClick={handleTrackClick("Fund Search")}
              >
                <IconsContainer
                  iconUrl={searchIconWhite}
                  style={
                    pathname === PATH.FUND_SEARCH || pathname.includes(PATH.FUND_SEARCH_RESULT)
                      ? { opacity: "0.2" }
                      : { opacity: "1" }
                  }
                />
              </Link>
            </IconsSeparator>
          </Tooltip>
          <Tooltip content="Ask ALICE">
            <IconsSeparator>
              <IconsContainer
                iconUrl={alertIconWhite}
                onClick={tabIsActive || alertIsActive ? undefined : menuToggle}
              />
              <CrownAlert header alertIsActive={alertIsActive} menuToggle={menuToggle} />
            </IconsSeparator>
          </Tooltip>
          <Tooltip content="notification">
            <IconsSeparator>
              <IconsContainer
                iconUrl={notificationIconWhite}
                onClick={alertIsActive ? undefined : tabToggle}
              />
              <NotificationTab tabIsActive={tabIsActive} tabToggle={tabToggle} />
            </IconsSeparator>
          </Tooltip>

          <Tooltip content="profile">
            <ProfileIconWrapper>
              <UserProfileHeader
                headerStyle={headerStyle}
                tabIsActive={tabIsActive}
                alertIsActive={alertIsActive}
              />
            </ProfileIconWrapper>
          </Tooltip>
        </StyledHeaderRight>
      )}
    </StyledHeader>
  );
}
export default FundHeader;
