import { MAIN_CACHE_BUCKET_NAME } from "config/googleCloudConfig";
import { getFromGoogleBucket } from "./googleCloudStorage";
import { getFederatedToken } from "./okta/federatedAccessToken";

export const getSEPA = async (accessToken: string, id: string) => {
  const federatedToken = await getFederatedToken(accessToken);

  if (federatedToken) {
    const sepa = await getFromGoogleBucket(
      MAIN_CACHE_BUCKET_NAME,
      "",
      `SEPA/${id}.json`,
      federatedToken,
    );
    const sepaData = JSON.parse(new TextDecoder().decode(sepa).toString());
    return sepaData;
  }

  return null;
};
