// Copyright Marco Rapaccini and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.

/**
 * This is the dedicated component for displaying (or not) a table in the Party Profile's sections
 */
import { useState } from "react";
import {
  RespTableRow,
  TableBodyCell,
  TableContentContainer,
  HeaderCover,
} from "styles/tableContent/TableContent.styled";
import ManageFundsTable from "components/party/funds/table/ManageFunds";
import { FilterTable } from "../filter/FilterTable";
import fundsData from "../../data/fundsDummyData.json";

export function DisplayRow({ item, hasBorder = false }: { item: any; hasBorder: boolean }) {
  let displayRow = false;

  // check if there's at least one column of useful data. If yes, show the row.
  Object.entries(item).forEach(([_, field]: [string, string | any], j) => {
    if (field && field.trim() !== "") displayRow = true;
  });

  // check if there's at least one column of useful data. If yes, show the row.
  Object.entries(item).forEach(([, field]: [string, string | any]) => {
    if (field && field.trim() !== "") displayRow = true;
  });

  return displayRow ? (
    <RespTableRow hasBorder={hasBorder}>
      {Object.entries(item).map(([_, field]: [string, string | any], j) => (
        <TableBodyCell key={j}>{field || "-"}</TableBodyCell>
      ))}
    </RespTableRow>
  ) : null;
}

const FundData = fundsData;
export const ManagedFundsTableContent = ({ data }: { data: typeof FundData }) => {
  const [filter, setFilter] = useState("");
  const [hideFilter, setHideFilter] = useState(false);

  const dataToDisplay: typeof FundData = data;

  const searchFilter = (letter: string) => {
    setFilter(letter);
  };

  let displayTable = false;

  // check if there's at least one table cell of useful data. If yes, show the table
  data.forEach((item: object) => {
    Object.entries(item).forEach(([, field]: [string, string]) => {
      if (field && field.trim() !== "") displayTable = true;
    });
  });

  if (!displayTable) {
    return null;
  }

  return (
    <TableContentContainer noShadow noMarginTop>
      {!hideFilter && (
        <HeaderCover>
          <FilterTable setData={searchFilter} />
        </HeaderCover>
      )}
      <ManageFundsTable data={dataToDisplay} filter={filter} setHideFilter={setHideFilter} />
    </TableContentContainer>
  );
};
