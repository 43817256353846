import {
  ArrayData,
  FieldData,
  NameValue,
  ObjectType,
  ValetParking,
  ValetParkingRules,
} from "../types";

export const fieldValetParker = (
  valet: Record<string, string | NameValue[] | NameValue>,
  rule: FieldData,
  p: ObjectType,
  debug?: boolean,
) => {
  if (rule) {
    Object.entries(rule).forEach(([aspect, fieldData]) => {
      const aspectData = p[aspect];
      if (aspectData) {
        Object.entries(fieldData).forEach(([parking, aspectFieldName]) => {
          valet[parking] = aspectData[aspectFieldName];
        });
      }
    });
  }
};

export const arrayValetParker = (
  valet: Record<string, string | NameValue[] | NameValue>,
  rule: ArrayData,
  p: ObjectType,
  debug?: boolean,
) => {
  if (rule) {
    Object.entries(rule).forEach(([parkingArrayName, parkingFromAspectArray]) => {
      if (debug)
        console.log(
          "  arrayValetParker",
          "parkingArrayNameis",
          parkingArrayName,
          "parkingFromAspectArrayis",
          parkingFromAspectArray,
        );
      const aspectDataArray = p[parkingFromAspectArray.array];
      if (aspectDataArray) {
        let arr: NameValue[];
        if (!Array.isArray(aspectDataArray)) {
          arr = [aspectDataArray];
        } else arr = aspectDataArray;
        if (debug)
          valet[parkingArrayName] = arr.map((aspectDetails) => {
            const resultData: NameValue = {};
            Object.entries(parkingFromAspectArray.fields).forEach(
              ([parkingFieldName, aspectFieldName]) => {
                resultData[parkingFieldName] = aspectDetails[aspectFieldName];
              },
            );
            return resultData;
          });
      }
    });
  }
};

export const valetParker: ValetParking =
  (rules: ValetParkingRules, debug?: boolean) => (p: ObjectType) => {
    const result: Record<string, string | NameValue[] | NameValue> = {};
    if (rules) {
      Object.entries(rules).forEach(([valetName, rule]) => {
        const valet: NameValue = {};
        if (rule.fields) fieldValetParker(valet, rule.fields, p, debug);
        if (rule.arrays) arrayValetParker(valet, rule.arrays, p, debug);
        result[valetName] = valet;
      });
    }
    return result;
  };
