import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  min-height: 100vh;
  padding-bottom: 69px;
  box-sizing: border-box;
  overflow-x: hidden;
  width: 100vw;
`;

export const Content = styled.div`
  width: 100%;
  padding: 32px;
`;

export const Nav = styled.div`
  display: flex;
  margin-bottom: 45px;

  @media (min-width: 768px) {
    justify-content: flex-end;
  }
`;

export const OutletCover = styled.div`
  overflow-y: none;

  @media (min-width: 768px) {
    max-height: 85vh;
  }
`;
