// Copyright Marco Rapaccini and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.

import {
  LandingPageMenuContainer,
  LandingPageMenuMain,
} from "styles/landingPageMenue/LandingPageMenu.styled";
import { useAppData } from "hooks/useAppData";
import { pageDetails } from "constants/landingPageMenu";
import LandingPageCard from "../landingPageCard/LandingPageCard";

function LandingPageMenu() {
  const { isDidoDemo, isT360, isUserPartOfOrganization } = useAppData();

  return (
    <LandingPageMenuContainer>
      <LandingPageMenuMain>
        {pageDetails({ isDidoDemo, isT360, isUserPartOfOrganization }).map(
          (item, i) =>
            item.isUserAllowed && (
              <LandingPageCard
                key={i}
                landingPageCardDetails={{
                  link: item.link,
                  iconURL: item.iconURL,
                  iconURLHover: item.iconURLHover,
                  text: item.text,
                }}
              />
            ),
        )}
      </LandingPageMenuMain>
    </LandingPageMenuContainer>
  );
}

export default LandingPageMenu;
