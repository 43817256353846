import styled from "styled-components";

export const ContainerFunds = styled.div`
  margin-bottom: 40px;
  background-color: #ECEBE6;
  padding: 20px 20px;
`;

export const HubwiseContainerFunds = styled.div`
  margin-bottom: 40px;
  background-color: #D3D3D2;
  padding: 20px 20px;
`;

export const Field = styled.div<{ legal: boolean; isArray: boolean }>`
  font-family: Roboto Condensed;
  width: 50%;
  height: ${({ isArray }) => (isArray ? "fit-content" : "39px")};
  background: ${({ legal, theme }) => (legal ? theme.colors.PRIMARY[25] : theme.colors.WHITE)};
  border: ${({ legal, theme }) =>
    legal ? `1px solid ${theme.colors.PRIMARY[400]}` : `1px solid ${theme.colors.GREYED[300]}`};
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.GREY[500]};
  padding-left: 14px;
  padding: ${({ isArray }) => (isArray ? "10px 8px" : "")};
  display: flex;
  flex-direction: ${({ isArray }) => (isArray ? "column" : "row")};
  align-items: ${({ isArray }) => (isArray ? "flex-start" : "center")};
  @media (max-width: 768px) {
    width: 100%;
    margin-top: 20px;
  }
`;

export const Value = styled.div`
  font-family: "Roboto Condensed";
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.GREYED[700]};
  width: 50%;
  margin-right: 10px;
  text-wrap: wrap;
  @media (max-width: 768px) {
    width: 100%;
  }
`;