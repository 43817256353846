import { FieldName, FieldValue } from "styles/SectionSharedItems.styled";
import { Button } from "styles/admin/Dashboard.styled";
import { RequestByDiv } from "styles/funds/index.styled";
import { AdminFundsType } from "types/funds";
import { CcIdListProps } from "types";

export function RequestBy({ data }: { data: CcIdListProps }) {
  const date = new Date(data.createdAt).toLocaleDateString("en-US");
  return (
    <RequestByDiv>
      <FieldName>On-boarding request</FieldName>
      <FieldValue data-testid="request-by">{data.createdBy}</FieldValue>
      <FieldValue data-testid="due-date">{date}</FieldValue>
      {/* TODO: implement button handler */}
      <Button type="button">More details</Button>
    </RequestByDiv>
  );
}
