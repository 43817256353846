// Copyright Marco Rapaccini, Marcello Di Fronzo Gravallese and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.

/**
 * This is the dedicated component for Key People's tiles (a tile = a person)
 */

import { useEffect, useState } from "react";
import { Section, Person, PersonProperty, PersonPositionDepartment } from "types/keyPeopleModal";
import { TilesContainer } from "styles/party/keyPeople/Tiles.styled";
import { TilesGroup } from "./TilesGroup";
import { IndividualPersonData } from "../../../types";

export function Tiles({ data }: { data: IndividualPersonData[] }) {
  const [sectionsList, setSectionsList] = useState<Section[]>([]);
  const [peopleList, setPeopleList] = useState<Person[]>([]);
  const [modalUCI, setModalUCI] = useState<string | string[] | undefined>("");

  const groupingKeyPeople = () => {
    const sections: Section[] = [];
    const people: Person[] = [];

    data.map((keyPerson: IndividualPersonData) => {
      const person: Person = {
        uci: "",
        properties: [], // ,
        // posDep: []
      };
      Object.entries(keyPerson).forEach(([fieldName, fieldValue]: [string, string]) => {
        const tmpProperty: PersonProperty = {
          propertyName: fieldName,
          propertyValue: fieldValue,
        };

        if (tmpProperty.propertyValue.includes(";")) {
          tmpProperty.propertyValue = fieldValue.split(";");
          tmpProperty.propertyValue.forEach((value, index, array) => {
            array[index] = value.trim();
          });
        }
        person.properties.push(tmpProperty);
      });

      const fullName: PersonProperty | undefined = person.properties.find(
        (value) => value.propertyName === "Full Name",
      );
      const position: PersonProperty | undefined = person.properties.find(
        (value) => value.propertyName === "Position",
      );
      const department: PersonProperty | undefined = person.properties.find(
        (value) => value.propertyName === "Board, Committee or Dept",
      );
      const uci: PersonProperty | undefined = person.properties.find(
        (value) => value.propertyName === "UCI (Unique Contact Identifier)",
      );
      const appointmentDate: PersonProperty | undefined = person.properties.find(
        (value) => value.propertyName === "Appointment Date",
      );
      const resignationDate: PersonProperty | undefined = person.properties.find(
        (value) => value.propertyName === "Resignation Date",
      );

      const posDep: PersonPositionDepartment = {
        fullName: fullName?.propertyValue,
        position: position?.propertyValue,
        department: department?.propertyValue,
        appointmentDate: appointmentDate?.propertyValue,
        resignationDate: resignationDate?.propertyValue,
        uci: uci?.propertyValue,
      };

      person.uci = uci?.propertyValue as string;

      if (Array.isArray(posDep.department)) {
        posDep.department.forEach((department) => {
          const tmpSection: Section = {
            groupName: department,
            posDep: [],
          };
          if (!sections.find((value) => value.groupName === tmpSection.groupName))
            sections.push(tmpSection);
        });
      } else {
        const tmpSection: Section = {
          groupName: posDep.department as string,
          posDep: [],
        };
        if (!sections.find((value) => value.groupName === tmpSection.groupName))
          sections.push(tmpSection);
      }

      /* This remove automatically the arrays */
      const section = sections.find((value) => value.groupName === posDep.department);
      if (
        typeof section !== "undefined" &&
        !section.posDep.find((value) => value.uci === posDep.uci)
      )
        section.posDep.push(posDep);
      const personInPeople: Person | undefined = people.find((value) => value.uci === person.uci);

      if (typeof personInPeople !== "undefined") {
        personInPeople.properties.forEach((inPeopleProperty, index, array) => {
          let tmpArrayProperty: string[];
          const property = person.properties.find(
            (value) => value.propertyName === inPeopleProperty.propertyName,
          );

          if (
            typeof property !== "undefined" &&
            inPeopleProperty.propertyValue !== property.propertyValue
          ) {
            if (Array.isArray(inPeopleProperty.propertyValue)) {
              if (Array.isArray(property.propertyValue)) {
                tmpArrayProperty = inPeopleProperty.propertyValue.concat(property.propertyValue);
              } else {
                inPeopleProperty.propertyValue.push(property.propertyValue);
                tmpArrayProperty = inPeopleProperty.propertyValue;
              }
            } else if (Array.isArray(property.propertyValue)) {
              property.propertyValue.push(inPeopleProperty.propertyValue);
              tmpArrayProperty = property.propertyValue;
            } else {
              tmpArrayProperty = [property.propertyValue, inPeopleProperty.propertyValue];
            }

            tmpArrayProperty
              .sort()
              .splice(tmpArrayProperty.indexOf(""), tmpArrayProperty.lastIndexOf("") + 1);

            array[index] = {
              propertyName: inPeopleProperty.propertyName,
              propertyValue: Array.from(new Set(tmpArrayProperty)),
            };
          }
        });
      } else people.push(person);
      return [];
    });

    setSectionsList(sections);
    setPeopleList(people);
  };

  useEffect(() => {
    groupingKeyPeople();
  }, []);

  return (
    <div>
      <TilesContainer>
        {sectionsList.map(
          (section: Section, index: number) =>
            section.posDep.length > 0 && (
              <TilesGroup
                key={index}
                peopleList={peopleList}
                section={section}
                modalUCI={modalUCI}
                setModalUCI={setModalUCI}
              />
            ),
        )}
      </TilesContainer>
    </div>
  );
}
