import {
  CenterContent,
  GeneralDetailsContainer,
  GeneralDetailsEntitySubTitle,
  GeneralDetailsEntityTitle,
  GeneralDetailsFGC,
  GeneralDetailsFieldName,
  GeneralDetailsRightColumnContent,
  GeneralDetailsSingleRow,
  TitleCover,
  Modal,
  Overlay,
  ModalContent,
  LizardImg,
  CloseModal,
  ButtonDiv,
  Button,
  OpenModal,
  WarningIcon,
} from "styles/party/General.styled";
import ModalComponent from "components/modal";
import useModal from "hooks/useModal";
import { GeneralDetailsType } from "types/funds";
import AliceModalTooltip from "components/AliceModalTooltip";
import {
  SingleRowContainer,
  SingleRowInternalContainer,
} from "../../styles/SectionSharedItems.styled";
import { fieldValueFilterFromBVD } from "../FieldValueFilter";

// dedicated function for each single row
function GeneralDetailsRow({ fieldName, fieldValue }: { fieldName: string; fieldValue: string }) {
  const modal = useModal();

  return fieldValue && fieldValue.trim() !== "" ? (
    <SingleRowContainer>
      <SingleRowInternalContainer>
        <GeneralDetailsSingleRow>
          <GeneralDetailsFieldName>{fieldName}</GeneralDetailsFieldName>
          {/* use the alice modal tooltip component to display a crown icon when you hover over the text */}
          <AliceModalTooltip
            selectedField={fieldValueFilterFromBVD(fieldValue)}
            selectedKey={fieldName}
          >
            <div style={{ display: "flex" }}>
              {fieldValueFilterFromBVD(fieldValue)}
              <WarningIcon>
                {(fieldName === "National Legal Form" || fieldName === "Date of Incorporation") && (
                  <OpenModal onClick={modal.open} />
                )}
              </WarningIcon>
            </div>
          </AliceModalTooltip>
        </GeneralDetailsSingleRow>
      </SingleRowInternalContainer>
      <ModalComponent controller={modal}>
        <Modal className="modal">
          <Overlay onClick={modal.close} />
          <ModalContent>
            <LizardImg />
            <CloseModal onClick={modal.close} />
            <h2>Under Investigation</h2>
            <p>We are in the process of verifying this information</p>
            <ButtonDiv>
              <Button onClick={modal.close}>Discard</Button>
              <Button onClick={modal.close}>Ok</Button>
            </ButtonDiv>
          </ModalContent>
        </Modal>
      </ModalComponent>
    </SingleRowContainer>
  ) : null;
}

function GeneralDetailsGroup({ data }: { data: [string, any][] }) {
  return (
    <GeneralDetailsFGC>
      {data.map(([name, field]: [string, string | any], j) =>
        Array.isArray(field) ? (
          field.map((item, i) => <GeneralDetailsGroup data={item} key={i} />)
        ) : (
          <GeneralDetailsRow fieldName={name} fieldValue={field} key={j} />
        ),
      )}
    </GeneralDetailsFGC>
  );
}

// main function
export function GeneralDetails({ data, isAlert }: { data: GeneralDetailsType; isAlert?: boolean }) {
  // variables that we use for specific special fields
  let entityTitle: string = "";
  let entitySubTitle: string = "";
  let entityCountry: string = "";
  // this is just for generic data
  const dataToDisplay: [string, any][] = [];

  if (!data || Object.entries(data)?.length === 0) {
    return null;
  }
  Object.entries(data)?.forEach(([name, field]: [string, string | any]) => {
    // this part is hardcoded, so has to be changed in the future!
    switch (name) {
      case "Legal Name English Version":
        entityTitle = field;
        break;
      case "Legal Name":
        entitySubTitle = field;
        break;
      case "Country":
        entityCountry = field;
        break;
      case "id":
        entityCountry = "";
        break;
      default:
        // generic data that don't need dedicated fields
        dataToDisplay.push([name, field]);
    }
  });

  if (!dataToDisplay?.length) return null;
  return (
    <GeneralDetailsContainer>
      <CenterContent>
        <GeneralDetailsRightColumnContent>
          {/* Here are the special fields that have a dedicated styling */}
          <TitleCover isAlert={isAlert!}>
            <GeneralDetailsEntityTitle>{entityTitle || entitySubTitle}</GeneralDetailsEntityTitle>
          </TitleCover>
          <GeneralDetailsEntitySubTitle>{entityCountry}</GeneralDetailsEntitySubTitle>
          {/* Then, the rest of the data */}
          <GeneralDetailsGroup data={dataToDisplay} />
        </GeneralDetailsRightColumnContent>
      </CenterContent>
    </GeneralDetailsContainer>
  );
}
