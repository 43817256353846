import IframeDivBasic from "../../components/ecp/EcpBasic";

function Ecp() {
  return (
    <div className="Home">
      <IframeDivBasic />
    </div>
  );
}
export default Ecp;
