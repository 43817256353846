import data from "data/clientAliceRequests.json";
import { ActivityType, useTrackActivity } from "hooks/useTracker";
import useEffectOnce from "hooks/useEffectOnce";
import { TRACKER } from "constants/tracker";
import AliceRequestComponent from "../../components/admin/AliceRequest";

const AliceRequests = () => {
  const { track } = useTrackActivity();

  useEffectOnce(() => {
    track(ActivityType.LOAD_PAGE, TRACKER.CLIENT_ALICE_REQUEST);
  });
  return <AliceRequestComponent data={data} isParty={false} />;
};

export default AliceRequests;
