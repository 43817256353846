import { ActivityType, useTrackActivity } from "hooks/useTracker";
import useEffectOnce from "hooks/useEffectOnce";
import { TRACKER } from "constants/tracker";
import HubwiseProfilesComponent from "components/hubwise-dashboard/Profiles";

const HubwiseProfiles = () => {
    const { track } = useTrackActivity();

    useEffectOnce(()=>{
        track(ActivityType.LOAD_PAGE, TRACKER.HUBWISE_DASHBOARD_PROFILES);
    });
    return <HubwiseProfilesComponent />
}

export default HubwiseProfiles;