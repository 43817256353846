import { FunctionComponent, useState } from "react";
import { DataProps } from "types";
import { useToast } from "hooks/useToast";

const ReportsComponent: FunctionComponent<{ data: DataProps[]; isParty?: boolean }> = ({    
    data,
    isParty,
}) => {
    // this data object contains mock entries that can be used in reports
    console.log("data: ", data);

    const { toast } = useToast();    
    const [reports, setReports] = useState([]);

    const fetchReports = async () => {
      // this local URL will be replaced later
        const reportsLocalApiURL = "http://127.0.0.1:5000/reports";
        await fetch(reportsLocalApiURL)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Reports - Failed to fetch reports data");
          }
          return response;
        })
        .then((response: Response) => response.json())
        .then((data) => setReports(data))
        .catch((err: Error) => toast(err.toString()));
    }

    const insertData = async (queryParams: any) => {
      // this local URL will be replaced later
      const insertDataLocalApiURL = "http://127.0.0.1:5000/api/insert";
      await fetch(insertDataLocalApiURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(queryParams)
      })
      .then(response => response.json())
      .then(data => console.log(data))
      .catch((err: Error) => toast(err.toString()));
    }

    const handleInsertClick = () => {
      const queryParams = {
        "createdAt": "2022-12-14T11:16:05.526Z",
        "email": "janedoe@deutschebank.com",
        "party": "Citi Bank",
        "status": "ACTIVE",
        "streamId": "DRgKrwUmmYsWOGwxPoDiv3///nnXpu0WdA==",
        "chatName": "fourth"
      }

      insertData(queryParams);
    }
    

    return (
        // reports design to be added below
      <div>
        {/* these buttons are for testing the api. To be removed once testing ends */}
        <button onClick={fetchReports}>Fetch reports</button>
        <button onClick={handleInsertClick}>Insert Data</button>
      </div>
        

    )
}

export default ReportsComponent;