// Copyright Marco Rapaccini and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.

/**
 * This is the main component for Party Profile
 */

import { Fragment, FunctionComponent } from "react";
import { Accordion } from "components/party/accordion/Accordion";
import { PerformanceType } from "types";
import { Flex, ListCover, InfoList } from "styles/party/funds/index.styled";
import { FundDocumentsType } from "types/funds";
import NoData from "components/noData/NoData";
import { FundsD, FundsInfoD, PerformanceD, groupD } from "constants/party";
import TopHoldings from "./TopHoldings";
import List from "./List";
import SubFunds from "./subfunds/SubFunds";
import FundsDocument from "./FundsDocument";
import { PortfolioBreakdown } from "../portfolioBreakdown/PortfolioBreakdown";
import FundManager from "./fundManager/FundManager";
import FundManagerDetails from "./fundManager/FundManagerDetails";

const performanceData = (obj: PerformanceType) => {
  const res: { [key: string]: string } = {};

  for (const [key, value] of Object.entries(obj)) {
    if (PerformanceD.includes(key)) {
      res[key] = value;
    }
  }
  return res;
};

const groupData = (obj: PerformanceType) => {
  const res: { [key: string]: string } = {};

  for (const [key, value] of Object.entries(obj)) {
    if (groupD.includes(key)) {
      res[key] = value;
    }
  }
  return res;
};

const FundsInfoData = (obj: PerformanceType) => {
  const res: { [key: string]: string } = {};

  for (const [key, value] of Object.entries(obj)) {
    if (FundsInfoD.includes(key)) {
      res[key] = value;
    }
  }
  return res;
};

const fundsData = (obj: PerformanceType) => {
  const res: { [key: string]: string } = {};

  for (const [key, value] of Object.entries(obj)) {
    if (FundsD.includes(key)) {
      res[key] = value;
    }
  }
  return res;
};

const GroupedDetails: FunctionComponent<{
  data: any;
  fundDocuments: FundDocumentsType;
  hideSubFund?: boolean;
}> = ({ data, fundDocuments, hideSubFund = false }) => {
  if (!data) return null;
  return (
    <div>
      <Accordion title="Overview" light noTopBorder opened>
        <Flex mb>
          <ListCover>
            <List data={performanceData(data)} title="Performance" />
          </ListCover>
          <InfoList>
            <ListCover>
              <List small data={FundsInfoData(data)} title="Fund Info" />
            </ListCover>
            <ListCover>
              <List small data={groupData(data)} title="Group Info" />
            </ListCover>
          </InfoList>
          <ListCover>
            <TopHoldings
              data={data?.["Top Holdings"] ? JSON.parse(data?.["Top Holdings"]) : []}
              title="Top 10 Holdings"
            />
          </ListCover>
        </Flex>
      </Accordion>
      <Accordion title="Portfolio Breakdown" light noTopBorder opened>
        <PortfolioBreakdown data={data || []} />
      </Accordion>
      <Accordion title="Fund Documents" light noTopBorder opened>
        <FundsDocument data={fundDocuments} />
      </Accordion>
      <Accordion title="Fund Manager" light noTopBorder opened>
        {data?.["Fund Manager Details"] ? (
          <FundManagerDetails
            data={data?.["Fund Manager Details"] ? JSON.parse(data?.["Fund Manager Details"]) : []}
          />
        ) : data?.["Fund Manager"] ? (
          <FundManager data={data?.["Fund Manager"] ? JSON.parse(data?.["Fund Manager"]) : []} />
        ) : (
          <NoData />
        )}
      </Accordion>
      {!hideSubFund && (
        <Accordion title="Sub-Funds" light noTopBorder opened>
          {!data?.["Child Fund ID"] ? (
            <NoData />
          ) : (
            <SubFunds filter="" childFundIds={data["Child Fund ID"]} />
          )}
        </Accordion>
      )}
    </div>
  );
};

export default GroupedDetails;
