import { FunctionComponent } from "react";
import { CcIdCardDashboardContainer } from "styles/funds/CcIdDashboardCard.styled";
import data from "data/ccIdListData.json"

import CcIdList from "./CcIdList";

const HubwiseProfilesComponent: FunctionComponent =  () => {
    return (
        <CcIdCardDashboardContainer>
            <CcIdList data={data}/>
        </CcIdCardDashboardContainer>
    )
}

export default HubwiseProfilesComponent;