/**
 * This is the dedicated component for every search result item that is showed for every Search Results page in Party Manager.
 */

import { useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { Link, useLocation } from "react-router-dom";
import { Result } from "../../../types";
import { SearchResultsAccordion } from "../searchResultsAccordion/SearchResultsAccordion";
import {
  SearchResultItemContainer,
  SearchResultItemArea,
  SearchResultItemLeftArea,
  SearchResultItemRightArea,
  SearchResultsItemName,
  SearchResultsItemCountry,
  AccordionActionButton,
  OpenPartyIcon,
  CloseAccordionIcon,
  OpenAccordionIcon,
  GuoIconContainer,
  GuoImage,
  RightIcons,
} from "../../../styles/searchResult/SearchResultsItem.styled";

export function SearchResultsItem({
  category,
  id,
  isGuo,
  mainActivity,
  name,
  identifiers,
  entityTypeInternal,
  description,
  country,
  iso,
}: Result) {
  const [showItem, setShowItem] = useState(false);

  // get current page url and check if it is the funds search result page
  const location = useLocation();
  const [path] = useState(location.pathname);
  const IS_FUND_SEARCH_RESULT = path.includes("/funds/search-results");

  return (
    <div>
      <SearchResultItemContainer show={showItem}>
        <SearchResultItemArea onClickCapture={() => setShowItem(true)}>
          <SearchResultItemLeftArea>
            {isGuo && (
              <GuoIconContainer>
                <GuoImage src={`${process.env.PUBLIC_URL}/assets/guoIcon.svg`} />
              </GuoIconContainer>
            )}
            {name && <SearchResultsItemName>{name}</SearchResultsItemName>}
            <SearchResultsItemCountry>
              {iso && (
                <div>
                  <ReactCountryFlag
                    countryCode={iso}
                    svg
                    style={{ width: "1.5em", height: "1.5em" }}
                    title={iso}
                  />
                </div>
              )}
              {country && <div>{country}</div>}
            </SearchResultsItemCountry>
            {entityTypeInternal && <div>{entityTypeInternal}</div>}

            <RightIcons>
              <AccordionActionButton>
                {/* direct to funds page if link is clicked from the search-result page, else direct to party profile page  */}
                <Link to={IS_FUND_SEARCH_RESULT ? `/funds/${id}` : `/party/${id}`}>
                  <OpenPartyIcon
                    title={IS_FUND_SEARCH_RESULT ? "Open Funds Profile" : "Open Party Profile"}
                  />
                </Link>
              </AccordionActionButton>
              <AccordionActionButton onClick={() => setShowItem(!showItem)}>
                {showItem === true ? (
                  <CloseAccordionIcon title="Close this result" />
                ) : (
                  <OpenAccordionIcon title="Open this result" />
                )}
              </AccordionActionButton>
            </RightIcons>
          </SearchResultItemLeftArea>
          <SearchResultItemRightArea onClick={!showItem ? () => setShowItem(true) : undefined}>
            {showItem && (
              <SearchResultsAccordion
                idForNeo4j={id}
                mainActivity={mainActivity}
                category={category as string}
                description={description}
                identifiers={identifiers}
              />
            )}
          </SearchResultItemRightArea>
        </SearchResultItemArea>
      </SearchResultItemContainer>
    </div>
  );
}
