import data from "data/adminAliceRequests.json";
import { ActivityType, useTrackActivity } from "hooks/useTracker";
import useEffectOnce from "hooks/useEffectOnce";
import { TRACKER } from "constants/tracker";
import AliceRequestComponent from "../../components/admin/AliceRequest";

function AliceRequests() {
  const { track } = useTrackActivity();

  useEffectOnce(() => {
    track(ActivityType.LOAD_PAGE, TRACKER.ALICE_REQUEST);
  });
  return <AliceRequestComponent data={data} />;
}

export default AliceRequests;
