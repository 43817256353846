import {
  DefaultSearchContainer,
  SearchInputForm,
  BasicSearchContainer,
  BasicSearchInput,
  SearchIconContainer,
  SearchIconButton,
  CancelIconContiner,
} from "styles/Search.styled";
import CancelIcon from "@material-ui/icons/Cancel";
import { useState, ChangeEvent, useEffect, useRef } from "react";
import qs from "query-string";
import { useOktaAuth } from "@okta/okta-react";
import { ActivityType, useTrackActivity } from "hooks/useTracker";
import { TRACKER } from "constants/tracker";
import { SearchSuggestions } from "../searchSuggestion/SearchSuggestion";
import { DefaultSearchInput } from "../../types";
import LoadingSpinner from "../LoadingSpinner";

export default function BasicSearch() {
  const { authState } = useOktaAuth();
  // we use qs library for passing multiple parameters to the search props
  const queryParam = qs.parse(window.location.search);
  // state for default search input
  const initialInputState: DefaultSearchInput = {
    ...queryParam,
    entityName: "",
  };

  const [searchInput, setSearchInput] = useState("");
  const [searchInput4Call, setSearchInput4Call] = useState(initialInputState);
  const [isReadyToGetSuggestions, setIsReadyToGetSuggestions] = useState(false);
  const [message, setMessage] = useState("");
  const { track } = useTrackActivity();

  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value);
    setMessage(event.target.value);
  };

  const handleClick = () => {
    // clear input value
    setMessage("");
    setIsReadyToGetSuggestions(false);
  };
  const getSuggestions = (event: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
    event.preventDefault();

    if (searchInput.trim() !== "") {
      setSearchInput4Call((prevState) => ({
        ...prevState,
        entityName: searchInput,
      }));

      setIsReadyToGetSuggestions(true);
    } else {
      setIsReadyToGetSuggestions(false);
    }
  };

  useEffect(() => {
    // set isReadyToGetSuggestions to false , if the input field is empty
    if (!searchInput) {
      setIsReadyToGetSuggestions(false);
    }
  }, [searchInput]);

  useEffect(() => {
    // focus the input on mounting the page
    inputRef.current?.focus();
  }, []);

  if (!authState) return <LoadingSpinner />;

  if (!authState) return <LoadingSpinner />;

  return (
    <DefaultSearchContainer>
      <SearchInputForm autoComplete="off">
        <BasicSearchContainer>
          <BasicSearchInput>
            <input
              type="text"
              placeholder="Type your search"
              onChange={handleChange}
              name="entityName"
              value={message}
              ref={inputRef}
            />
          </BasicSearchInput>
          <SearchIconContainer>
            <SearchIconButton
              onClick={(event) => {
                getSuggestions(event);
                track(ActivityType.SEARCH, TRACKER.BASIC_SEARCH, { search_term: message });
              }}
            >
              <span />
            </SearchIconButton>
          </SearchIconContainer>
          {message !== "" ? (
            <CancelIconContiner>
              <CancelIcon onClick={handleClick} />
            </CancelIconContiner>
          ) : (
            ""
          )}
        </BasicSearchContainer>
      </SearchInputForm>
      {isReadyToGetSuggestions && (
        <SearchSuggestions
          searchString={qs.stringify(searchInput4Call)}
          isIdentifierSearch={false}
        />
      )}
    </DefaultSearchContainer>
  );
}
