import { getFromGoogleBucket } from "services/googleCloudStorage";
import { getFederatedToken } from "services/okta/federatedAccessToken";
import { FINGERPRINTS_FILE_NAME, IMPORT_VARIABLES_FILE_NAME } from "config/mainConfig";
import {
  ASPECT_FILES_PATH,
  ASPECT_FILES_SOURCE_BUCKET_NAME, FINANCIAL_GROUP_CACHE_DESTINATION_BUCKET_NAME,
  MAIN_CACHE_BUCKET_NAME,
  VALET_PARKING_RULES_BUCKET_NAME,
  VALET_PARKING_RULES_PATH,
} from "config/googleCloudConfig";
import { BufferAndLoadUrlData, Entity, FileDetails, ImportVariables, PartyDetails } from "types";
import { callGCFunction } from "services/callGCFunction";
import { WRITE_TO_MAIN_CACHE_GCF } from "config/googleCloudFunctionsConfig";
import { valetParker } from "./valetParking";
import { readImportVariables } from "./readImportVariables";
import { idToFileDetails, makeFingerprint, parseEntityListFile } from "./party";

/**
 * Download all the data and metadata necessary for displaying an Entity in Party Profile.
 * Please, consider that this function can be improved.
 * @param {string} accessToken OAuth2.0 token
 * @param {string} id the Party unique identifier
 */
export const getDataAndMetadata = async (accessToken: string, id: string) => {
  const federatedToken = await getFederatedToken(accessToken);
  if (federatedToken) {
    // download the .t360import import variables
    const importVariablesArrayBuffer: ArrayBuffer = await getFromGoogleBucket(
      ASPECT_FILES_SOURCE_BUCKET_NAME,
      ASPECT_FILES_PATH,
      `${IMPORT_VARIABLES_FILE_NAME}`,
      federatedToken,
    );
    if (importVariablesArrayBuffer.byteLength > 0) {
      const importVariables: ImportVariables = await readImportVariables(
        importVariablesArrayBuffer,
      );
      // download the fingerPrint file
      // const fingerPrintArrayBuffer: ArrayBuffer = await getFromGoogleBucket(
      //   ASPECT_FILES_SOURCE_BUCKET_NAME,
      //   ASPECT_FILES_PATH,
      //   `${importVariables.fullFingerPrintFile}/${FINGERPRINTS_FILE_NAME}`,
      //   federatedToken,
      // );
      // if (fingerPrintArrayBuffer.byteLength > 0) {
      //   const rawFingerPrint: string = new TextDecoder().decode(fingerPrintArrayBuffer);
      //   const fileDetails: FileDetails = await idToFileDetails(id, importVariables);
        // try to download the EntityList file from the cache

      // Downloads the json file
      let party_details_buff: ArrayBuffer = await getFromGoogleBucket(
        MAIN_CACHE_BUCKET_NAME,
        "",
        id + ".json",
        federatedToken,
      );
      // if there's no entityList in cache
      if (party_details_buff.byteLength === 0) {
        // call the GCF that writes in cache
        const apiCallBody = {
          accessToken,
          fromBucketName: ASPECT_FILES_SOURCE_BUCKET_NAME,
          filePath: ASPECT_FILES_PATH,
          toBucketName: MAIN_CACHE_BUCKET_NAME,
          storagePath: "entityZip",
          mod: "6",
          toGit: "[1, 1, 1]",
          fromGit: "[1, 1, 1]",
          entityId: id,
        };
        await callGCFunction(
            WRITE_TO_MAIN_CACHE_GCF,
            apiCallBody,
            "Write to main cache",
            "Party Profile",
        );
        // once written in the cache, re-read again the cache in order to download the cached EntityList
        party_details_buff = await getFromGoogleBucket(
            MAIN_CACHE_BUCKET_NAME,
            "",
            id + ".json",
            federatedToken,
        );
      }

        // // prepare all the data necessary for parsing the EntityList file
        // const bufferAndMetadata: BufferAndLoadUrlData = {
        //   buffer: entityListBuffer,
        //   fileDetails,
        //   url: [
        //     `bucket: ${importVariables.outputBucket}`,
        //     fileDetails.entitylistTarOnGoogleCloud,
        //     fileDetails.pathInsideTar,
        //   ],
        //   metadataForDebug: ["extract entity list"],
        // };
        // // finally parse the EntityList file
        // const parsedEntityListFile: Entity = parseEntityListFile(
        //   makeFingerprint(rawFingerPrint),
        //   bufferAndMetadata,
        // );

        // download the Valet Parking Rules, that will allow us to 'park' data into the different sections (accordions) of the Party Profile
        const rawValetParkingRules = await getFromGoogleBucket(
          VALET_PARKING_RULES_BUCKET_NAME,
          "",
          VALET_PARKING_RULES_PATH,
          federatedToken,
        );
        const valetParkingRules = JSON.parse(
          new TextDecoder().decode(rawValetParkingRules).toString(),
        );

        const party_details = JSON.parse(
            new TextDecoder().decode(party_details_buff).toString());

        // apply the Valet Parking rules in order to prepare the data for display
        const dataReadyToDisplay: PartyDetails = valetParker(valetParkingRules)(
          party_details,
        ) as PartyDetails;

        console.log(
          "Party Details: ",
          party_details_buff,
          "\nJson Rules: ",
          valetParkingRules,
          "\nData ready to display: ",
          dataReadyToDisplay,
        );

        return dataReadyToDisplay;
      }
    }
  }
