// Copyright Marco Rapaccini and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.
import { MainTitleH1 } from "styles/alertAirtableTesting/MainTitle.styled";
/**
 * Component dedicated for titles.
 * @param text: title text content that has to be displayed
 */
function MainTitle({ text }: { text: string }) {
  return <MainTitleH1>{text}</MainTitleH1>;
}

export default MainTitle;
