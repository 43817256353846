export const getItem = <T>(key: string): T => {
  const data = localStorage.getItem(key);
  return data ? JSON.parse(data) : {};
};

export const setItem = <T>(key: string, data: T) => {
  return localStorage.setItem(key, JSON.stringify(data));
};

export const removeItem = (key: string) => {
  return localStorage.removeItem(key);
};
