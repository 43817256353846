import { Link, useLocation } from "react-router-dom";
import { MouseEventHandler, useState } from "react";
import {
  StyledHeader,
  StyledHeaderLeft,
  StyledHeaderRight,
  StyledHeaderTitle,
  StyledLogo,
  StyledMenuIcon,
  IconsContainer,
  IconsSeparator,
  t360Logo,
  t360LogoPurple,
  searchIconWhite,
  askAliceIconWhite,
  alertIconWhite,
  adminRoomsIconWhite,
  searchIconBlue,
  askAliceIconBlue,
  alertIconBlue,
  notificationIconWhite,
  notificationIconBlue,
  adminRoomsIconBlue,
  ProfileIconWrapper,
} from "styles/header/Header.styled";
import { PATH } from "constants/index";
import { ActivityType, useTrackActivity } from "hooks/useTracker";
import Tooltip from "components/Tooltip";
import switchValue from "utils/switchValue";
import { TRACKER } from "constants/tracker";
import SideMenu from "./SideMenu";
import NotificationTab from "../notificationTab/NotificationTab";
import CrownAlert from "../crownAlert/CrownAlert";
import UserProfileHeader from "./UserProfileHeader";
import { HeaderStyle, HeaderType } from "../../types";

function Header({
  headerStyle,
  isT360Email,
  isUserAuthenticated,
}: {
  headerStyle: HeaderStyle;
  isT360Email: boolean;
  isUserAuthenticated: boolean;
}) {
  const { pathname } = useLocation();
  const IS_ADMIN =
    pathname.includes(PATH.ADMIN) ||
    pathname.includes(PATH.CLIENT) ||
    pathname.includes(PATH.DIDO_DEMO);

  // show or hide side menu
  const [showSideMenu, setShowSideMenu] = useState(false);

  // for crowd alert
  const [alertIsActive, setAlertIsActive] = useState(false);
  const menuToggle = () => setAlertIsActive(!alertIsActive);

  // for notification tab
  const [tabIsActive, setTabIsActive] = useState(false);
  const tabToggle = () => setTabIsActive(!tabIsActive);

  // redirect to admin room if user is from t360, if not client room
  const adminPath = isT360Email ? PATH.ADMIN_ALICE_REQUEST : PATH.CLIENT_ALICE_REQUEST;

  const { track } = useTrackActivity();

  function handleTrackClick(button: string): MouseEventHandler<HTMLAnchorElement | HTMLDivElement> {
    return () => {
      track(ActivityType.BUTTON_CLICK, `${button} page`, {
        button_clicked: `${button} Button`,
        clicked_from: TRACKER.NAVIGATION_HEADER,
      });
    };
  }

  if (IS_ADMIN) return null;
  return (
    <StyledHeader headerStyle={headerStyle} className="container">
      <SideMenu show={showSideMenu} close={() => setShowSideMenu(false)} />
      <StyledHeaderLeft>
        <StyledMenuIcon fontSize="large" onClick={() => setShowSideMenu(true)} />
        <Link to={!alertIsActive ? PATH.INDEX : pathname}>
          <StyledLogo
            headerStyle={headerStyle}
            iconURL={[HeaderType.DEFAULT].includes(headerStyle) ? t360Logo : t360LogoPurple}
          />
          <StyledHeaderTitle headerStyle={headerStyle}>
            {switchValue(headerStyle, {
              [HeaderType.DEFAULT]: "Party Manager",
              default: "T360",
            })}
          </StyledHeaderTitle>
        </Link>
      </StyledHeaderLeft>
      {isUserAuthenticated && (
        <StyledHeaderRight>
          <Tooltip content="search">
            <IconsSeparator>
              <Link
                to={!alertIsActive ? PATH.SEARCH : pathname}
                onClick={handleTrackClick("Search")}
              >
                <IconsContainer
                  iconUrl={
                    [HeaderType.DEFAULT].includes(headerStyle) ? searchIconWhite : searchIconBlue
                  }
                  style={
                    pathname === PATH.SEARCH || pathname.includes(PATH.SEARCH_RESULT)
                      ? { opacity: "0.2" }
                      : { opacity: "1" }
                  }
                />
              </Link>
            </IconsSeparator>
          </Tooltip>
          <Tooltip content="Ask ALICE">
            <IconsSeparator>
              <IconsContainer
                iconUrl={
                  [HeaderType.DEFAULT].includes(headerStyle) ? alertIconWhite : alertIconBlue
                }
                onClick={tabIsActive || alertIsActive ? undefined : menuToggle}
              />
              <CrownAlert header alertIsActive={alertIsActive} menuToggle={menuToggle} />
            </IconsSeparator>
          </Tooltip>
          <Tooltip content="Alert">
            <IconsSeparator>
              <Link
                to={!alertIsActive ? PATH.ALERT : pathname}
                onClick={handleTrackClick("Alice Modal")}
              >
                <IconsContainer
                  iconUrl={
                    [HeaderType.DEFAULT].includes(headerStyle)
                      ? askAliceIconWhite
                      : askAliceIconBlue
                  }
                  style={pathname === PATH.ALERT ? { opacity: "0.2" } : { opacity: "1" }}
                />
              </Link>
            </IconsSeparator>
          </Tooltip>
          <Tooltip content="notification">
            <IconsSeparator>
              <IconsContainer
                iconUrl={
                  [HeaderType.DEFAULT].includes(headerStyle)
                    ? notificationIconWhite
                    : notificationIconBlue
                }
                onClick={alertIsActive ? undefined : tabToggle}
              />
              <NotificationTab tabIsActive={tabIsActive} tabToggle={tabToggle} />
            </IconsSeparator>
          </Tooltip>
          {headerStyle !== HeaderType.FUNDS && (
            <Tooltip content={isT360Email ? "admin room" : "client room"}>
              <IconsSeparator>
                <Link
                  to={!alertIsActive ? adminPath : pathname}
                  onClick={handleTrackClick("Admin Icon")}
                >
                  <IconsContainer
                    iconUrl={
                      [HeaderType.DEFAULT].includes(headerStyle)
                        ? adminRoomsIconWhite
                        : adminRoomsIconBlue
                    }
                    style={IS_ADMIN ? { opacity: "0.2" } : { opacity: "1" }}
                  />
                </Link>
              </IconsSeparator>
            </Tooltip>
          )}
          <Tooltip content="profile">
            <ProfileIconWrapper>
              <UserProfileHeader
                headerStyle={headerStyle}
                tabIsActive={tabIsActive}
                alertIsActive={alertIsActive}
              />
            </ProfileIconWrapper>
          </Tooltip>
        </StyledHeaderRight>
      )}
    </StyledHeader>
  );
}
export default Header;
