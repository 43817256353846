import { NoDataToDisplay } from "components/noData/ComingSoon";
import { IdentifiersData } from "../../types";
import IdentifierLists from "./IdentifierLists";

type Obj = { [key: string]: any };

function present(arr: Obj[], c: string) {
  let check = false;

  arr.forEach((item) => {
    if (item?.["National ID Label"] === c) {
      check = true;
    }
  });

  return check;
}

function format(obj: Obj): Obj[] {
  if (!obj) return [];

  const arr: Obj[] = [];

  Object.entries(obj).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((v) => {
        if (!present(arr, v?.["National ID Label"])) {
          arr.push({
            "National ID Label": v?.["National ID Label"],
            "National ID Number": v?.["National ID Number"],
          });
        }
      });
    } else {
      arr.push({ "National ID Label": key, "National ID Number": value });
    }
  });

  return arr;
}

function convertToList(arr: Obj[]): Obj[][] {
  if (!arr || !arr.length) return [];
  const mid = Math.ceil(arr.length / 2);

  const arrs = [arr.slice(0, mid > 3 ? mid : 3), arr.slice(mid > 3 ? mid : 3)];

  return arrs as Obj[][];
}

// general layout for Accordion's data (not for specific sections)
export function Identifiers({ data }: { data: IdentifiersData }) {
  // dataFilter is a function that chooses if we should display BanksOnly (bo) or NON-BanksOnly (no_bo)

  return !data ? <NoDataToDisplay /> : <IdentifierLists data={convertToList(format(data))} />;
}
