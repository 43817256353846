import { useState } from "react";
import { Link } from "react-router-dom";
import { PATH, ROLE } from "constants/index";
import {
  IconsContainer,
  IconsSeparator,
  Header,
  StyledMenuIcon,
  Container,
} from "../../styles/admin/Navbar.styled";
import { HeaderType } from "../../types";
import CrownAlert from "../crownAlert/CrownAlert";
import UserProfileHeader from "../header/UserProfileHeader";
import NotificationTab from "../notificationTab/NotificationTab";
import MobileMenu from "./MobileMenu";
import { useAppData } from "../../hooks/useAppData";

function AdminNavbar() {
  const { status } = useAppData();

  const searchIconBlue =
    process.env.PUBLIC_URL + status === "dark"
      ? "/assets/header-icon-search-white.svg"
      : "/assets/header-icon-search-blue.svg";
  const askAliceIconBlue =
    process.env.PUBLIC_URL + status === "dark"
      ? "/assets/header-icon-ask-alice-white.svg"
      : "/assets/header-icon-ask-alice-blue.svg";
  const alertIconBlue =
    process.env.PUBLIC_URL + status === "dark"
      ? "/assets/header-icon-alert-white.svg"
      : "/assets/header-icon-alert-blue.svg";
  const notificationIconBlue =
    process.env.PUBLIC_URL + status === "dark"
      ? "/assets/header-icon-notifications-white.svg"
      : "/assets/header-icon-notifications-blue.svg";
  const adminRoomsIconBlue =
    process.env.PUBLIC_URL + status === "dark"
      ? "/assets/header-icon-admin-rooms-white.svg"
      : "/assets/header-icon-admin-rooms-blue.svg";

  // for crowd alert
  const [alertIsActive, setAlertIsActive] = useState(false);
  const menuToggle = () => setAlertIsActive(!alertIsActive);

  const [showMobileMenu, setShowMobileMenu] = useState(false);

  // for notification tab
  const [tabIsActive, setTabIsActive] = useState(false);
  const tabToggle = () => setTabIsActive(!tabIsActive);
  return (
    <Container>
      <StyledMenuIcon data-testid="menu" onClick={() => setShowMobileMenu(true)} />
      <Header>
        <IconsSeparator>
          <Link to={!alertIsActive ? PATH.SEARCH : window.location.pathname}>
            <IconsContainer
              iconUrl={searchIconBlue}
              style={
                window.location.pathname === PATH.SEARCH ||
                window.location.pathname.includes(PATH.SEARCH_RESULT)
                  ? { opacity: "0.2" }
                  : { opacity: "1" }
              }
            />
          </Link>
        </IconsSeparator>
        <IconsSeparator>
          <IconsContainer
            iconUrl={alertIconBlue}
            onClick={tabIsActive || alertIsActive ? undefined : menuToggle}
          />
          <CrownAlert alertIsActive={alertIsActive} menuToggle={menuToggle} />
        </IconsSeparator>
        <IconsSeparator>
          <Link to={!alertIsActive ? PATH.ALERT : window.location.pathname}>
            <IconsContainer
              iconUrl={askAliceIconBlue}
              style={
                window.location.pathname === PATH.ALERT ? { opacity: "0.2" } : { opacity: "1" }
              }
            />
          </Link>
        </IconsSeparator>
        <IconsSeparator>
          <IconsContainer
            iconUrl={notificationIconBlue}
            onClick={alertIsActive ? undefined : tabToggle}
          />
          <NotificationTab tabIsActive={tabIsActive} tabToggle={tabToggle} />
        </IconsSeparator>
        <IconsSeparator>
          <Link to={!alertIsActive ? PATH.ADMIN_ALICE_REQUEST : window.location.pathname}>
            <IconsContainer
              iconUrl={adminRoomsIconBlue}
              style={
                window.location.pathname.includes(ROLE.ADMIN)
                  ? { opacity: "0.2" }
                  : { opacity: "1" }
              }
            />
          </Link>
        </IconsSeparator>
        <UserProfileHeader
          headerStyle={HeaderType.ADMIN}
          tabIsActive={tabIsActive}
          alertIsActive={alertIsActive}
        />
      </Header>
      <MobileMenu show={showMobileMenu} close={() => setShowMobileMenu(false)} />
    </Container>
  );
}

export default AdminNavbar;
