import { useState, useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { isUserAuthenticated } from "../services/okta/okta";

const useOktaAccessToken = (): string | undefined => {
  const { authState, oktaAuth } = useOktaAuth();
  const [accessToken, setAccessToken] = useState<string>();

  useEffect(() => {
    if (isUserAuthenticated(authState)) setAccessToken(oktaAuth.getAccessToken());
  }, [authState, oktaAuth]);

  return accessToken;
};
export default useOktaAccessToken;
