import { useContext } from "react";
import { displayTokens, isUserPartOfGroup } from "services/okta/okta";
import { COLOR_MODE, PARTY_MAP } from "constants/index";
import { useOktaAuth } from "@okta/okta-react";
import { AuthState } from "types/okta";
import { AppContext } from "../contexts/App";
import { isT360Email } from "../utils/index";
import { isUserAuthenticated } from "../services/okta/okta";
import { IColors } from "../types/styled";
import { styledComponentTheme } from "../constants/styled";

const { DARK_MODE_COLORS, LIGHT_MODE_COLORS } = styledComponentTheme;

/**
 *
 * @returns globally shared data
 */
export const useAppData = () => {
  const {
    user,
    setUser,
    status,
    setStatus,
    opacity,
    setOpacity,
    selectedParty,
    setSelectedParty,
    selectedFunds,
    setSelectedFunds,
    token,
  } = useContext(AppContext);
  const { authState } = useOktaAuth();
  const tokens = displayTokens(authState) as AuthState;

  const claims = tokens?.accessToken?.claims;

  const party = claims?.Organisation?.[0] as keyof typeof PARTY_MAP;

  const colors: IColors = status === COLOR_MODE.DARK ? DARK_MODE_COLORS : LIGHT_MODE_COLORS;

  return {
    user,
    setUser,
    status,
    setStatus,
    colors,
    opacity,
    setOpacity,
    isT360Email: isT360Email(user?.email || ""),
    party,
    pid: PARTY_MAP[party],
    isT360: isUserPartOfGroup(authState, "Organisation", "t360_party"),
    isNetworkManagerRole: isUserPartOfGroup(authState, "Roles", "network_manager_role"),
    isTechRole: isUserPartOfGroup(authState, "Roles", "tech_role"),
    isDidoDemo: isUserPartOfGroup(authState, "Access_Permissions", "dido_demo_access"),
    isHubWise: isUserPartOfGroup(authState, "Organisation", "hubwise_party"),
    isFund: isUserPartOfGroup(authState, "Access_Permissions", "funds_inspect"),
    isPartOfRoles: (roles: string[]) =>
      roles.every((role) => isUserPartOfGroup(authState, "Roles", role)),
    isUserPermission: (permissions: string[]) =>
      permissions.every((permission) =>
        isUserPartOfGroup(authState, "Access_Permissions", permission),
      ),
    isUserPartOfOrganization: (organizations: string[]) =>
      organizations.every((organization) =>
        isUserPartOfGroup(authState, "Organisation", organization),
      ),
    isUserAuthenticated: isUserAuthenticated(authState),
    claims,
    tokens,
    token,
    selectedParty,
    setSelectedParty,
    selectedFunds,
    setSelectedFunds,
  };
};
