import type { FunctionComponent } from "react";
import { Container } from "styles/party/funds/index.styled";
import { FundDocumentsType } from "types/funds";
import GroupedDetails from "./GroupedDetails";

const Funds: FunctionComponent<{
  data: any;
  fundDocuments: FundDocumentsType;
  hideSubFund?: boolean;
}> = ({ data, fundDocuments, hideSubFund }) => {
  return (
    <Container>
      <GroupedDetails data={data} fundDocuments={fundDocuments} hideSubFund={hideSubFund} />
    </Container>
  );
};

export default Funds;
