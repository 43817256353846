import { HttpHeaders } from "../types";

/**
 * Abstraction of the built-in fetch function for retrieving data from an endpoint.
 * @param {string} endpointPath the complete path to the endpoint (e.g. `https://my-api.com/get/something`)
 * @param {HttpHeaders} headers optional headers for the HTTP request
 */
export const fetchData = async (
  endpointPath: string,
  headers?: HttpHeaders,
): Promise<ArrayBuffer> => {
  try {
    const response = await fetch(endpointPath, { headers });
    if (!response.ok) throw new Error("Network response was not OK");
    return await response.arrayBuffer();
  } catch {
    return new ArrayBuffer(0);
  }
};
