import styled from "styled-components";

export const TilesContainer = styled.div`
  display: block;
  column-span: all;
  margin-top: 20px;
  &:first-child {
    margin-top: 0px;
  }
`;
