import styled from "styled-components";

type AccordionState = "opened" | "closed";

export const TilesGroupContainer = styled.div`
  margin: 0 10px;
  font-size: 16px;
  border-top: 1px solid ${({ theme }) => theme.colors.SECONDARY[500]};
  &:last-child {
    margin-bottom: 0px;
  }
`;

export const TilesGroupTitle = styled.div`
  cursor: pointer;
  margin: 24px 10px 24px 0;
  display: flex;
  justify-content: space-between;
`;

export const TilesGroupIcon = styled.span<{ rotate: AccordionState }>`
  display: inline-block;
  vertical-align: middle;
  width: 12px;
  height: 8px;
  background-image: url(${`${process.env.PUBLIC_URL}/assets/arrowAccordionIcon.svg`});
  background-size: 12px 8px;
  transform: ${(props) => `rotate(${props.rotate === "opened" ? 0 : 90}deg)`};
  margin-right: 8px;
  filter: ${({ theme }) =>
    theme.colors.WHITE !== "#FFFFFF"
      ? "saturate(0.8) brightness(0.86) hue-rotate(180deg) invert(1)"
      : "none"};
`;

export const TilesGroupName = styled.div`
  display: inline-block;
  text-transform: capitalize;
  font-family: Roboto Condensed;
  color: ${({ theme }) => theme.colors.PRIMARY[400]};
  font-size: 18px;
  line-height: 21.09px;
  font-weight: 500;
`;

export const TilesGroupCollection = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  gap: 15px;
  &::after {
    content: "";
    width: 32%;
  }
`;
