import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`;

export const SingleRow = styled.div`
  margin-bottom: 32px;
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: flex-start;
  flex-direction: row;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const Section = styled.div<{ full?: boolean }>`
  padding: 0 12px;
  border-radius: 12px;
  width: ${({ full }) => (full ? "100%" : "400px")};
  margin-right: 32px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.PRIMARY[15]};
  &:last-child {
    margin-right: 0;
  }
`;

export const SectionTitle = styled.div`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.GREY[500]};
  font-weight: 500;
  font-size: 16px;
  font-family: "Roboto Condensed";
  margin-top: 14px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const SmallTitle = styled.div`
  font-size: 16px;
  font-weight: 400;
  font-family: "Roboto Condensed";
  width: 180px;
  color: ${({ theme }) => theme.colors.PRIMARY[400]};
  margin-right: 10px;
`;

export const BarsContainer = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const LabelContainer = styled.div`
  width: 100%;
  margin-bottom: 60px;
`;

export const Cover = styled.div`
  .button-cover {
    display: flex;
    justify-content: flex-end;

    button {
      padding: 3px 10px;
      border-radius: 5px;
    }
  }
`;
