import styled from "styled-components";

export const CustomCardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 20px;
  flex: 28%;
`;

export const CustomCard = styled.div<{ iconURL: string; iconURLHover: string; status: string }>`
  width: 200px;
  height: 200px;
  padding: 20px 20px;

  border-radius: 20px;
  border: 4px solid transparent;
  &:hover {
    border: 4px solid #2e1a61;
  }

  & div {
    text-align: center;
    & span {
      display: inline-block;
      width: 144px;
      height: 144px;
      background-image: ${(props) => `url(${process.env.PUBLIC_URL + props.iconURL})`};
      background-size: cover;
    }
    & p {
      color: ${(props) => (props.status === "dark" ? "#fff" : "#808080")};
      text-decoration: none;
      font-size: 24px;
      line-height: 28px;
      font-weight: 500;
    }
  }

  &:hover {
    & div {
      & span {
        background-image: ${(props) => `url(${process.env.PUBLIC_URL + props.iconURLHover})`};
      }
      & p {
        color: ${(props) => (props.status === "dark" ? "#fff" : "#2E1A61")};
      }
    }
  }
`;
