import NoDataContent from "components/noData/NoDataContent";
import { FC } from "react";
import { List, Text, CContainer } from "styles/party/funds/index.styled";

const ListData: FC<{ data: { [key: string]: string } | null; small?: boolean; title?: string }> = ({
  data,
  small,
  title,
}) => {
  return (
    <CContainer>
      <div className="title-cover">
        {title ? (
          <Text light data-testid="title">
            {title}
          </Text>
        ) : null}
      </div>
      {data ? (
        Object.entries(data).map(([key, value]) => (
          <List key={key} small={small} data-testid="tags">
            <p>{`${key}:`}</p>
            <p>{value}</p>
          </List>
        ))
      ) : (
        <NoDataContent />
      )}
    </CContainer>
  );
};

export default ListData;
