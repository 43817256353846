import styled from "styled-components";

 export const LandingPageMenuContainer = styled.div`
 min-height: 80vh;
 padding: 0 0.5rem;
 display: flex;
 justify-content: center;
 align-items: center;
`

export const LandingPageMenuMain = styled.div`
  padding: 2rem 1rem;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  @media (max-width: 968px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
