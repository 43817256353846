import { ManagedFundsTableContent } from "components/tableContent/ManagedFundsTableContent";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getAllFunds } from "services/funds";
import LoadingSpinner from "components/LoadingSpinner";
import NoData from "components/noData/NoData";
import { LoadingSpinnerDiv } from "styles/LoadingSpinner.styled";
import { ActivityType, useTrackActivity } from "hooks/useTracker";
import useEffectOnce from "hooks/useEffectOnce";
import { PartyDetails } from "types";
import { ContactInformation } from "types/party";
import { TRACKER } from "constants/tracker";
import { AdminFundsInitialState, AdminFundsType } from "types/funds";
import { AdminFunds } from "./admin";
import * as adminFundsDataDummy from "../../../data/adminFundsDummyData.json";
import { Accordion } from "../accordion/Accordion";

export function ManagedFunds({
  token,
  data,
  partyId,
}: {
  token?: string;
  data: PartyDetails;
  partyId: string;
}) {
  const { id } = useParams<{ id: string }>();
  const [fundsData, setFundsData] = useState([]);
  const [loading, setLoading] = useState(false);

  const { track } = useTrackActivity();

  const [adminFundsData, setAdminFundsData] = useState<AdminFundsType>();

  // TODO: this object: mockObjForAccordions- is hardcoded. Will be removed when data is available
  const mockObjForAccordions = {
    "Fund Manager Level": "Data coming soon",
    History: "Data coming soon",
    "Size/Group Info": "Data coming soon",
    Activities: "Data coming soon",
    Identifiers: "Data coming soon",
    "Managed Fund Manager": fundsData,
    Memberships: "Data coming soon",
    Hierarchy: "Data coming soon",
    "Ratings and Ranking": "Data coming soon",
    Financials: "Data coming soon",
    "Auditors & Advisors": "Data coming soon",
    Regulators: "Data coming soon",
    "KYC/Due Diligence": "Data coming soon",
    "Mergers & Acquisitions": "Data coming soon",
    "Boards & Committees": "Data coming soon",
    Geolocation: "Data coming soon",
    "Top 10 Holdings": "Data coming soon",
    Roles: "Data coming soon",
    Portfolio: "Data coming soon",
    "Fund Document": "Data coming soon",
    "Key Personnel": "Data coming soon",
  };

  const localState: AdminFundsInitialState = {
    documents: 0,
    status: [false, false, false, false, false],
    riskRanking: "",
  };

  useEffect(() => {
    const updatedData: AdminFundsType = {
      fromApi: adminFundsDataDummy,
      initialState: localState,
    };
    setAdminFundsData(updatedData);
  }, [adminFundsDataDummy]);

  useEffectOnce(() => {
    track(ActivityType.LOAD_PARTY_PROFILE, TRACKER.FUNDS, {
      party_name: (data["Contact Information"] as ContactInformation).Name,
      party_id: partyId,
    });
  });

  useEffect(() => {
    if (token && id) {
      setLoading(true);
      getAllFunds(token, id)
        .then((data) => {
          setFundsData(data);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [token, id]);
  return loading ? (
    <LoadingSpinnerDiv>
      <LoadingSpinner />
    </LoadingSpinnerDiv>
  ) : !fundsData || fundsData?.length === 0 ? (
    <NoData />
  ) : (
    <div>
      {adminFundsData &&
        // TODO: replace mockObjForAccordions with data when data is available
        Object.entries(mockObjForAccordions!)?.map(([key, dataToDisplay]: [string, any], i) => {
          // this part is hardcoded, so has to be changed in the future!
          switch (key) {
            case "Fund Manager Level":
              return (
                <Accordion key={i} title="Fund Manager Level" noTopBorder small>
                  <p>{dataToDisplay}</p>
                </Accordion>
              );
            case "History":
              return (
                <Accordion key={i} title="History" small>
                  <p>{dataToDisplay}</p>
                </Accordion>
              );
            case "Size / Group Info":
              return (
                <Accordion key={i} title="Size / Group Info " small>
                  <p>{dataToDisplay}</p>
                </Accordion>
              );
            case "Activities":
              return (
                <Accordion key={i} title="Activities" small>
                  <p>{dataToDisplay}</p>
                </Accordion>
              );
            case "Identifiers":
              return (
                <Accordion key={i} title="Identifiers" small>
                  <p>{dataToDisplay}</p>
                </Accordion>
              );
            case "Managed Fund Manager":
              return (
                <Accordion key={i} title="Managed Fund Manager" small>
                  <ManagedFundsTableContent data={dataToDisplay} />
                </Accordion>
              );
            case "Membership":
              return (
                <Accordion key={i} title="Membership" small>
                  <p>{dataToDisplay}</p>
                </Accordion>
              );
            case "Hierarchy":
              return (
                <Accordion key={i} title="Hierarchy" small>
                  <p>{dataToDisplay}</p>
                </Accordion>
              );
            case "Ratings and Ranking":
              return (
                <Accordion key={i} title="Ratings and Ranking" small>
                  <p>{dataToDisplay}</p>
                </Accordion>
              );
            case "Financials":
              return (
                <Accordion key={i} title="Financials" small>
                  <p>{dataToDisplay}</p>
                </Accordion>
              );
            case "Auditors & Advisors":
              return (
                <Accordion key={i} title="Auditors & Advisors" small>
                  {/* <AdminFunds data={adminFundsData} stateSetter={setAdminFundsData} /> */}
                  <p>Placeholder</p>
                </Accordion>
              );
            case "Regulators":
              return (
                <Accordion key={i} title="Regulators" small>
                  <p>{dataToDisplay}</p>
                </Accordion>
              );
            case "KYC/Due Diligence":
              return (
                <Accordion key={i} title="KYC/Due Diligence" small>
                  <p>{dataToDisplay}</p>
                </Accordion>
              );
            case "Mergers & Acquisitions":
              return (
                <Accordion key={i} title="Mergers & Acquisitions" small>
                  <p>{dataToDisplay}</p>
                </Accordion>
              );
            case "Boards & Committees":
              return (
                <Accordion key={i} title="Boards & Committees" small>
                  <p>{dataToDisplay}</p>
                </Accordion>
              );
            case "Geolocation":
              return (
                <Accordion key={i} title="Geolocation" small>
                  <p>{dataToDisplay}</p>
                </Accordion>
              );
            case "Top 10 Holdings":
              return (
                <Accordion key={i} title="Top 10 Holdings" small>
                  <p>{dataToDisplay}</p>
                </Accordion>
              );
            case "Roles":
              return (
                <Accordion key={i} title="Roles" small>
                  <p>{dataToDisplay}</p>
                </Accordion>
              );
            case "Portfolio":
              return (
                <Accordion key={i} title="Portfolio" small>
                  <p>{dataToDisplay}</p>
                </Accordion>
              );
            case "Fund Document":
              return (
                <Accordion key={i} title="Fund Document" small>
                  <p>{dataToDisplay}</p>
                </Accordion>
              );
            case "Key Personnel":
              return (
                <Accordion key={i} title="Key Personnel" small>
                  <p>{dataToDisplay}</p>
                </Accordion>
              );
            default:
              // if it's not a specific case, just use the general layout
              return null;
          }
        })}
    </div>
  );
}
