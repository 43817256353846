// Copyright Marco Rapaccini and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.

  import HubwiseDashboardSideBar from "./HubwiseDashboardSideBar";
  import { OutletCover, Content } from "styles/admin/index.styled";
  import { Outlet } from "react-router-dom";
  import AdminNavbar from "../../components/admin/Navbar";
  import { Container } from "styles/funds/HubwiseDashboard.styled";
  
  function HubwiseDashboardMenu() {
  
    return (
      <Container>
        <HubwiseDashboardSideBar />
        <Content>
          {/* outlet  */}
          <OutletCover>
            <Outlet />
          </OutletCover>
        </Content>        
      </Container>
    );
  }
  
  export default HubwiseDashboardMenu;
  