import { FC } from "react";
import { Cover } from "styles/party/funds/index.styled";
import { FundManagerType } from "types/funds";
import ListFundManager from "./ListFundManager";

const keys = ["Fund Manager Name", "Fund Manager Since"];

const fundData = (obj: FundManagerType) => {
  const res: { [key: string]: string } = {};
  for (const [key, value] of Object.entries(obj)) {
    if (keys.includes(key)) {
      if (key === "Fund Manager Name") {
        res.Name = value;
      } else {
        res[key] = value;
      }
    }
  }
  return res;
};

const FundManagerDetails: FC<{ data: FundManagerType[] }> = ({ data }) => {
  if (!data?.length) return null;
  return (
    <Cover data-testid="fund-manager-details">
      {data.map((datum, i) => (
        <div className="each" key={i}>
          <ListFundManager data={fundData(datum)} key={datum["Fund Manager Name"]} />
        </div>
      ))}
    </Cover>
  );
};

export default FundManagerDetails;
