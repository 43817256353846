import type { FunctionComponent, MouseEvent, PropsWithChildren } from "react";
import Tooltip from "components/Tooltip";
import { DetailsIcon, DetailsIconWrapper, Container } from "styles/AliceModalTooltip.styled";
import { useAliceContext } from "contexts/AliceModalContext";

const crownIcon = `${process.env.PUBLIC_URL}/assets/crown.svg`;

const AliceModalTooltip: FunctionComponent<
  PropsWithChildren<{
    selectedField: string;
    selectedKey?: string;
    isCard?: boolean;
    isSmall?: boolean;
    isDisable?: boolean;
  }>
> = ({ children, selectedField, selectedKey, isCard, isSmall, isDisable }) => {
  const { menuToggle, isOpen } = useAliceContext();

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    if (!isOpen) {
      menuToggle(selectedField, selectedKey!);
    }

    event.stopPropagation();
  };

  return (
    <Container isCard>
      <div style={{ fontSize: isSmall ? "13px" : "16px" }}>{children}</div>
      {!isDisable && (
        <DetailsIconWrapper>
          <Tooltip content="Ask ALICE" askAlice>
            <DetailsIcon src={crownIcon} onClick={handleClick} />
          </Tooltip>
        </DetailsIconWrapper>
      )}
    </Container>
  );
};

export default AliceModalTooltip;
