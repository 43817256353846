import useEffectOnce from "hooks/useEffectOnce";
import { ActivityType, useTrackActivity } from "hooks/useTracker";
import { TRACKER } from "constants/tracker";
import HubwiseDashBoardMenu from "components/hubwise-dashboard/HubwiseDashboardMenu";

function HubwiseDashboard() {
    const { track } = useTrackActivity();
  
    useEffectOnce(() => {
      track(ActivityType.LOAD_PAGE, TRACKER.HUBWISE_DASHBOARD);
    });
  
    return (
      <div className="HubwiseDashboard">
        <HubwiseDashBoardMenu />
      </div>
    );
  }
  export default HubwiseDashboard;