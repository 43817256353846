// Copyright Marco Rapaccini, Marcello Di Fronzo Gravallese and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.

/**
 * This is the dedicated component for Key People's group of tiles (a group that has multiple people/tiles)
 */

import { Section, SetModal, Person } from "types/keyPeopleModal";
import { useState } from "react";
import {
  TilesGroupContainer,
  TilesGroupTitle,
  TilesGroupName,
  TilesGroupIcon,
  TilesGroupCollection,
} from "styles/party/keyPeople/TilesGroup.styled";
import { TilePerson } from "./TilePerson";

type AccordionState = "opened" | "closed";

export function TilesGroup({
  section,
  modalUCI,
  setModalUCI,
  peopleList,
}: {
  section: Section;
  modalUCI?: string | string[];
  setModalUCI: SetModal;
  peopleList: Person[];
}) {
  const [accordionState, setAccordionState] = useState<AccordionState>("closed");

  return (
    <TilesGroupContainer>
      <TilesGroupTitle
        data-testid="tiles-group-title"
        onClick={() => setAccordionState(accordionState === "closed" ? "opened" : "closed")}
      >
        <TilesGroupName>{section.groupName}</TilesGroupName>
        <TilesGroupIcon rotate={accordionState} />
      </TilesGroupTitle>
      {accordionState === "opened" && (
        // key people cards component container
        <TilesGroupCollection data-testid="tiles-group-collection">
          {section.posDep.map(
            (personPosDep, index) =>
              personPosDep && (
                <TilePerson
                  personPosDep={personPosDep}
                  peopleList={peopleList}
                  modalUCI={modalUCI}
                  setModalUCI={setModalUCI}
                  key={index}
                />
              ),
          )}
        </TilesGroupCollection>
      )}
    </TilesGroupContainer>
  );
}
