import { FunctionComponent, useState } from "react";
import {
  Container,
  StyledCloseIcon,
  Content,
  IconsSeparator,
  IconsContainer,
  Text,
  searchIconBlue,
  askAliceIconBlue,
  alertIconBlue,
  notificationIconBlue,
  adminRoomsIconBlue,
} from "styles/header/SideMenu.styled";
import CrownAlert from "components/crownAlert/CrownAlert";
import { Link, useLocation } from "react-router-dom";
import { useAppData } from "hooks/useAppData";
import NotificationTab from "components/notificationTab/NotificationTab";
import { PATH } from "constants/index";

const SideMenu: FunctionComponent<{
  show: boolean;
  close: () => void;
}> = ({ show, close }) => {
  const { pathname } = useLocation();
  const IS_ADMIN =
    pathname.includes(PATH.ADMIN) ||
    pathname.includes(PATH.CLIENT) ||
    pathname.includes(PATH.DIDO_DEMO);

  const { isT360Email } = useAppData();

  // for crowd alert
  const [alertIsActive, setAlertIsActive] = useState(false);
  const menuToggle = () => setAlertIsActive(!alertIsActive);

  // for notification tab
  const [tabIsActive, setTabIsActive] = useState(false);
  const tabToggle = () => setTabIsActive(!tabIsActive);

  const showAlice = () => {
    close();

    if (!tabIsActive && !alertIsActive) {
      menuToggle();
    }
  };

  const showNotification = () => {
    close();

    if (!alertIsActive) {
      tabToggle();
    }
  };

  // redirect to admin room if user is from t360, if not client room
  const adminPath = isT360Email ? PATH.ADMIN_ALICE_REQUEST : PATH.CLIENT_ALICE_REQUEST;

  return (
    <Container show={show}>
      <StyledCloseIcon onClick={close} />
      <Content>
        <Link to={!alertIsActive ? PATH.SEARCH : pathname} onClick={close}>
          <IconsSeparator>
            <IconsContainer
              iconUrl={searchIconBlue}
              style={
                pathname === PATH.SEARCH || pathname.includes(PATH.SEARCH_RESULT)
                  ? { opacity: "0.2" }
                  : { opacity: "1" }
              }
            />
            <Text>Search</Text>
          </IconsSeparator>
        </Link>
        <IconsSeparator onClick={showAlice}>
          <IconsContainer iconUrl={alertIconBlue} />
          <Text>Alice</Text>
          <CrownAlert alertIsActive={alertIsActive} menuToggle={menuToggle} />
        </IconsSeparator>
        <Link to={!alertIsActive ? PATH.ALERT : pathname} onClick={close}>
          <IconsSeparator>
            <IconsContainer
              iconUrl={askAliceIconBlue}
              style={pathname === PATH.ALERT ? { opacity: "0.2" } : { opacity: "1" }}
            />
            <Text>Alert</Text>
          </IconsSeparator>
        </Link>
        <IconsSeparator onClick={showNotification}>
          <IconsContainer iconUrl={notificationIconBlue} />
          <Text>Notification</Text>
          <NotificationTab tabIsActive={tabIsActive} tabToggle={tabToggle} />
        </IconsSeparator>
        <Link to={!alertIsActive ? adminPath : pathname} onClick={close}>
          <IconsSeparator>
            <IconsContainer
              iconUrl={adminRoomsIconBlue}
              style={IS_ADMIN ? { opacity: "0.2" } : { opacity: "1" }}
            />
            <Text>Admin Room</Text>
          </IconsSeparator>
        </Link>
      </Content>
    </Container>
  );
};

export default SideMenu;
