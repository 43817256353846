/**
 * Function that calls AirTable API and returns dummy data from a specific table.
 * @param tableName: name of the specific table
 * @return an AirTableJSON value
 */ import { AirTableJSON } from "../types";

export const fetchAirTable = async (tableName: string): Promise<AirTableJSON> => {
  const airTableURl = `https://api.airtable.com/v0/app8xLu42kFCaCBr2/${tableName}`;
  const airTableAPIKey = "keyNc1m0nLi3TeDjG"; // TODO: security - remove this API Key
  const fetchOptions = {
    headers: {
      Authorization: `Bearer ${airTableAPIKey}`,
    },
  };
  const airTableResponse = await fetch(airTableURl, fetchOptions);
  return airTableResponse.json();
};
