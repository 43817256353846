// Copyright Marco Rapaccini and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.
import { SubtitleH2 } from "styles/alertAirtableTesting/Subtitle.styled";

/**
 * Component dedicated for subtitles.
 * @param text: subtitle text content that has to be displayed
 */
function Subtitle({ text }: { text: string }) {
  return <SubtitleH2>{text}</SubtitleH2>;
}

export default Subtitle;
