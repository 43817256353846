import { FunctionComponent } from "react";
import CcIdList from "./CcIdList";
import {
    Container,
    Wrapper,
    Header,
    HeaderTextBig,
    HeaderTextSmall,
    HeaderText,
    Content,
    TableContentContainer
  } from "../../styles/admin/AliceRequest.styled";
  import useModal from "hooks/useModal";
  import data from "data/ccIdListData.json"

const HubwiseRequestsComponent: FunctionComponent =  () => {
    const modal = useModal();
    return (
        <Container>
            <Wrapper>
                <Header>
                    <HeaderText>
                        <HeaderTextBig>Client Requests Template</HeaderTextBig>
                        <HeaderTextSmall>Keep track of requests</HeaderTextSmall>
                    </HeaderText>
                </Header>
                <Content>
                    <TableContentContainer>
                        <CcIdList data={data}/>
                    </TableContentContainer>
                </Content>
            </Wrapper>
        </Container>
    )
};

export default HubwiseRequestsComponent;