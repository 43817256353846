// Copyright Marco Rapaccini and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.

import { ChangeEvent } from "react";
import { CustomSelectBox } from "styles/alert/airTable/SelectBox.styled";
import { SelectBoxDetails, SelectBoxOption, StringStateSet } from "../../../types";

/**
 * Component that renders a select box with custom options.
 * @param selectBoxDetails: an object that represents the name of the select and the list of options for the user
 * @param stateSetter: once the user selects an option, call the specific state setter
 * @param defaultOptionHidden: a string defining the default option, hidden for selection
 */
export function SelectBox({
  selectBoxDetails,
  stateSetter,
  defaultOptionHidden,
}: {
  selectBoxDetails: SelectBoxDetails;
  stateSetter: StringStateSet;
  defaultOptionHidden: string;
}) {
  const handleSelectOption = (event: ChangeEvent<HTMLSelectElement>) => {
    stateSetter(event.target.value);
  };

  return (
    <CustomSelectBox onChange={handleSelectOption} id={selectBoxDetails.selectBoxName}>
      <option value="" hidden>
        {defaultOptionHidden}
      </option>
      {selectBoxDetails.selectListOfOptions.map((selectBoxOption: SelectBoxOption) => {
        return (
          <option value={selectBoxOption.optionValue} key={selectBoxOption.optionValue}>
            {selectBoxOption.optionContent}
          </option>
        );
      })}
    </CustomSelectBox>
  );
}
