import { Routes, Route } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import { useAppData } from "hooks/useAppData";
import { PARTY } from "constants/index";
import HubwiseLandingPage from "pages/hubwiseLandingPage/HubwiseLandingPage";
import { RequiredAuth } from "./SecureRoute";
import { LoginCallback } from "@okta/okta-react";
import LoadingSpinner from "components/LoadingSpinner";

function SsnCAppRoutes () {
    const { isUserPartOfOrganization } = useAppData();
    return (  
        <Routes>  
            <Route
                path="login/callback"
                element={<LoginCallback loadingElement={<LoadingSpinner />} />}
                />
            <Route element={<RequiredAuth />}>
                {isUserPartOfOrganization([PARTY.HUBWISE]) ? (
                    <Route>
                        <Route path="/" element={<HubwiseLandingPage />} />
                        <Route path ="/*" element={<AppRoutes />} />
                    </Route>
                ) : (
                    <Route>
                        <Route path ="/*" element={<AppRoutes />} /> 
                    </Route>
                    
                )}
            </Route>
        </Routes>
    )
}

export default SsnCAppRoutes;