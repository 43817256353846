// Copyright Marco Rapaccini and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.


  import {
    SSnCLandingPageMenuContainer,
    SSnCLandingPageMenuMain, 
    VideoHolder,
    ssncVideo,
    SSnCLogoHolder,
    ssncLogo,
    LogoAndTextHolder
  } from "styles/ssncLandingPage/ssncLandingPage.styled";
  import { useEffect, useState } from "react";
  import { Link } from "react-router-dom";
  import { PATH } from "constants/index";
  
  function SSnCLandingPage() {
    const [ isVideo, setIsVideo ] = useState(true);
  
    useEffect(()=>{
      setTimeout(()=>{
        setIsVideo(false);
      },5500)
    },[])
  
    return (
      <SSnCLandingPageMenuContainer>
        <SSnCLandingPageMenuMain>
          {isVideo ? (
            <VideoHolder>
              <video autoPlay muted src={ssncVideo}></video>
            </VideoHolder>
          ) : (
            <Link to={PATH.HUBWISE_DASHBOARD} style={{ textDecoration: "none" }}>
                <LogoAndTextHolder>
                <SSnCLogoHolder>
                    <img src={ssncLogo} />              
                </SSnCLogoHolder>
                <h1>Fund Management</h1>
                <h3>Powered by T360</h3>
                </LogoAndTextHolder>
            </Link>
          )}
        </SSnCLandingPageMenuMain>      
      </SSnCLandingPageMenuContainer>    
    );
  }
  
  export default SSnCLandingPage;
  