import { useLocation, Link } from "react-router-dom";
import useModal from "hooks/useModal";
import { PATH } from "constants/index";
import SignOut from "components/alert/SignOut";
import { 
    Container,
    Content,
    MenuContainer,
    MenuItem,
    MenuItemIcon,
    MenuItemText,
    MenuLink,
    Header,
    signoutIcon,
 } from "styles/admin/Sidebar.styled";
 import { HUBWISE_DASHBOARD_MENU_ITEMS } from "constants/hubwiseDashdoardMenu";
 import { useAppData } from "hooks/useAppData";

 const isActive = (path: string, link: string) => {
    return path.includes(link);
  };

function HubwiseDashboardSideBar () {
    const { status } = useAppData();

    const hubwiseLogo =`${process.env.PUBLIC_URL}/assets/hubwise-logo-color.svg`;

    const { pathname } = useLocation();

    const signOutModal = useModal();

    const openSignOutModal = () => {
        signOutModal.open();
    };

    return (
        <Container>
            <SignOut controller={signOutModal} />
            <Content>
                <MenuContainer>
                    {HUBWISE_DASHBOARD_MENU_ITEMS.map((menu) => (
                        <MenuLink to={menu.link} key={menu.text}>
                            <MenuItem isActive={isActive(pathname, menu.link)}>
                                <MenuItemIcon iconURL={menu.icon} isActive={isActive(pathname, menu.link)} />
                                <MenuItemText>{menu.text}</MenuItemText>
                            </MenuItem>
                        </MenuLink>                        
                    ))}
                </MenuContainer>
                {/* signout */}
                <MenuItem onClick={openSignOutModal}>
                    <MenuItemIcon iconURL={signoutIcon} path={PATH.SIGNOUT} />
                    <MenuItemText>Sign Out</MenuItemText>
                </MenuItem>
            </Content>
        </Container>
    )
}

export default HubwiseDashboardSideBar;