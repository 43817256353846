import { DATE_RANGE, STATUS } from "constants/filter";
import { isAfter, isToday, sub } from "date-fns";
import { useEffect, useState } from "react";
import { Filter } from "types";
import { search } from "utils/search";

const useFilters = <T extends { createdAt: string; status: string }>(data: T[]) => {
  const [filter, setFilter] = useState({ search: "", date: "", status: "" });

  const [filteredData, setFilteredData] = useState<T[]>([]);

  const setFilterBy = (filterBy: Partial<Filter>) => {
    setFilter((f) => ({ ...f, ...filterBy }));
  };

  const onFilterChange = () => {
    let newData: T[] = [...data];

    // search data
    if (filter.search) {
      newData = search(newData, filter.search);
    }

    // filter by status
    if (filter.status === STATUS.ACTIVE) {
      newData = newData.filter(
        (datum) => datum.status.toLowerCase() === STATUS.ACTIVE.toLowerCase(),
      );
    } else if (filter.status === STATUS.CLOSED) {
      newData = newData.filter(
        (datum) => datum.status.toLowerCase() === STATUS.CLOSED.toLowerCase(),
      );
    } else if (filter.status === STATUS.UNREAD) {
      newData = newData.filter(
        (datum) => datum.status.toLowerCase() === STATUS.UNREAD.toLowerCase(),
      );
    }

    // filter by date
    if (filter.date === DATE_RANGE.TODAY) {
      newData = newData.filter((datum) => isToday(new Date(datum.createdAt)));
    } else if (filter.date === DATE_RANGE.SEVEN_DAYS) {
      newData = newData.filter((datum) =>
        isAfter(new Date(datum.createdAt), sub(new Date(), { days: 8 })),
      );
    } else if (filter.date === DATE_RANGE.THIRTY_DAYS) {
      newData = newData.filter((datum) =>
        isAfter(new Date(datum.createdAt), sub(new Date(), { days: 31 })),
      );
    }

    setFilteredData(newData);
  };

  useEffect(() => {
    onFilterChange();
  }, [filter]);

  // set data as filteredData on mount i.e , make data the initialValue of filteredData
  useEffect(() => {
    setFilteredData(data);
  }, []);

  return { filteredData, setFilter: setFilterBy };
};

export default useFilters;
