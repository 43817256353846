import { FieldName } from "styles/SectionSharedItems.styled";
import { StatusBullets, StatusDiv } from "styles/funds/index.styled";
import { SelectBoxDetails } from "types";
import { useEffect, useState } from "react";
import { green } from "@material-ui/core/colors";
import { Check } from "@material-ui/icons";
import { CircularProgress } from "@material-ui/core";
import { SelectBox } from "components/alert/airTable/SelectBox";
import { CcIdListProps } from "types";

export function Status({
  data
}: {
  data: CcIdListProps
}) {
  const [option, setOption] = useState<string>("");

  const statusProgress = new Array(5).fill(false);
  const selectBoxOptions: SelectBoxDetails = {
    selectBoxName: "information-gathering-select",
    selectListOfOptions: [],
  };

  useEffect(()=>{
    if(data.status === "Information gathering") {
      statusProgress[0]=true;
      statusProgress[1]=true;
    }
    if(data.status === "Information checking") {
      statusProgress[0]=true;
      statusProgress[1]=true;
      statusProgress[2]=true;
      statusProgress[3]=true;
    }
  },[data])

  return (
    <StatusDiv>
      <FieldName>Status</FieldName>
      <StatusBullets>
        {statusProgress.map(
          (el, index) =>
            (el === false && (
              <CircularProgress
                data-testid="circular-progress"
                key={index}
                size={15}
                style={{
                  color: green[500],
                  top: -6,
                  left: -6,
                  zIndex: 1,
                }}
              />
            )) ||
            (el === true && <Check data-testid="check-progress" key={index} color="primary" />),
        )}
      </StatusBullets>
      <SelectBox
        selectBoxDetails={selectBoxOptions}
        stateSetter={setOption}
        defaultOptionHidden="Information gathering"
      />
    </StatusDiv>
  );
}
