import React, { Dispatch, SetStateAction } from "react";
import { StatusProps } from "constants/index";
import { Core } from "cytoscape";
import { PartyData } from "./party";

export interface OktaConfig {
  /** okta client id */
  clientId: string;
  /** okta authentication server issuer id */
  issuer: string;
  /** redirect uri */
  redirectUri: string;
  /** scopes */
  scopes: string[];
  /** proof key for code exchange */
  pkce: boolean;
  /** disable http check for testing */
  disableHttpsCheck: string | boolean;
}

export interface LandingPageCardDetails {
  link: string;
  iconURL: string;
  iconURLHover: string;
  text: string;
}

export interface PageCardDetails {
  link: string;
  iconURL: string;
  iconURLHover: string;
  text: string;
  isUserAllowed: boolean;
}

export interface HeaderMenuItem {
  text: string;
  iconURL: string;
  url: string;
  onClick?: () => unknown;
}

export interface UserDetailsOkta {
  name: string;
  givenName?: string;
  familyName?: string;
  initials: string;
  email: string | undefined;
}

export interface UserDetailsEmail {
  email: string;
}

export const EmptyUserDetails: UserDetailsOkta = {
  name: "",
  initials: "",
  email: "",
};

export enum HeaderType {
  DEFAULT = "DEFAULT",
  ADMIN = "ADMIN",
  FUNDS = "FUNDS",
  NONE = "NONE",
}

export interface ToastContextType {
  toast: (message: string, status: StatusProps) => void;
  isOpen: boolean;
}

export type HeaderStyle =
  | HeaderType.DEFAULT
  | HeaderType.ADMIN
  | HeaderType.FUNDS
  | HeaderType.NONE;

export type PartyDetails = PartyData;

export interface AspectDetails {
  [name: string]: string;
}

export interface Result {
  id: string;
  mainActivity?: string;
  category?: string | unknown;
  identifiers?: Array<Array<string>>;
  description?: string;
  history?: string;
  name?: string;
  iso?: string;
  country?: string;
  entityType?: string;
  entityTypeBvD?: string;
  isGuo: boolean;
  isTargetEntity: boolean;
  totalAssets: number;
  entityTypeInternal?: string;
}

export interface TotalAssetsWithClosingDate {
  totalAssetsValue: number;
  closingDate: Date;
}

export interface FinancialGroupItem {
  entityId: string;
  previewData: PartyDetails;
}

export interface CreateFinancialGroupCachePayload {
  accessToken: string;
  fromBucketName: string;
  toBucketName: string;
  storagePath: string;
  mod: string;
  toGit: string;
  fromGit: string;
  entityId: string;
  limit?: string;
  filePath: string;
}

export interface RelatedEntity {
  id: string;
  name: string;
  iso?: string;
  isGuo?: boolean;
  type?: string;
  notInNeo?: boolean;
  targetName?: string;
}

export const initialUltimateOwnerState: RelatedEntity = {
  id: "",
  name: "",
  notInNeo: true,
};

export interface DefaultSearchInput {
  entityName: string;
}

export type SetSearchSuggestionsHasFinished = React.Dispatch<React.SetStateAction<boolean>>;

export interface CallNeo4jExternallyItem {
  guo_id: string | null;
  sub_id: string | null;
  target_id: string;
  status: string;
}

export interface OcrDocResponseFields {
  [key: string]: string;
}

export type TooltipProps = {
  content: string;
  askAlice?: boolean | undefined;
  propInModal?: boolean | undefined;
};

export interface OcrDocResponseItem {
  doc: OcrDocResponseFields;
  name_saved_sha: string;
  type: string;
}

export interface CSVData {
  data: Array<string>[];
}

export interface SelectedDocument {
  type: string;
  file: File;
}

export type CytoscapeWithNodeHtmlLabel = Core & {
  nodeHtmlLabel: any;
};

export interface DocumentFromOCRProps {
  ocrDocResponseItem: OcrDocResponseItem;
  setConfirmedDocuments: Dispatch<SetStateAction<OcrDocResponseItem[]>>;
  index: number;
  setDocSavedChecks: Dispatch<SetStateAction<boolean[]>>;
}

export interface DocumentType {
  name: string;
  value: string;
}

export interface DocumentsUploadProps {
  onClose: () => void;
  title: string;
  message: string;
  show: boolean;
}
export interface Metadata {
  pid: string;
  user_id: string;
  event_id: string;
  transaction_id: string;
  date_time: string;
}

export interface MetadataProps {
  metadata: Metadata;
  setMetadata: React.Dispatch<React.SetStateAction<Metadata>>;
}

export const initialMetadataValue: Metadata = {
  pid: "",
  user_id: "",
  event_id: "",
  transaction_id: "",
  date_time: "",
};

export interface FeedbackSingleObject {
  client_address: string;
  client_name: string;
  client_pid: string;
  document_address: string | null;
  document_dob: string | null;
  document_name: string | null;
  document_number: string | null;
  document_surname: string | null;
  document_type: string | null;
}

export interface SingleFeedbackResult {
  comment: string;
  data: FeedbackSingleObject[];
  rag: string;
}

export type StructuredData = [string, SingleFeedbackResult];

export interface CounterSingleObject {
  Bank: SingleFeedbackResult | object;
  CarRental: SingleFeedbackResult | object;
  Feedback: SingleFeedbackResult | object;
  PhoneShop: SingleFeedbackResult | object;
}

export interface CounterObject {
  [key: string]: CounterSingleObject;
}

export interface ComparatorSingleObject {
  [key: string]: SingleFeedbackResult;
}

export interface ComparatorObject {
  different_doc_addresses_uploaded_together: ComparatorSingleObject | SingleFeedbackResult | object;
  doc_expired: ComparatorSingleObject | SingleFeedbackResult | object;
  old_doc_used_after_new_used: ComparatorSingleObject | SingleFeedbackResult | object;
  same_details_different_doc_number: ComparatorSingleObject | SingleFeedbackResult | object;
  same_doc_number_different_date_of_birth: ComparatorSingleObject | SingleFeedbackResult | object;
  same_doc_number_different_name: ComparatorSingleObject | SingleFeedbackResult | object;
  utility_bill_address_different_names: ComparatorSingleObject | SingleFeedbackResult | object;
  same_document_number_different_address: ComparatorSingleObject | SingleFeedbackResult | object;
}

export interface AlertFeedbackType {
  rag: string;
  comparator: ComparatorObject;
  counter: CounterObject;
}

export const RAG_COLOURS: { [key: string]: string } = {
  RED: "#FF0000",
  AMBER: "#FFBF00",
  GREEN: "#00FF00",
};

export interface AirTableRecordFields {
  [key: string]: string;
}

export interface AirTableRecord {
  id: string;
  fields: AirTableRecordFields;
  createdTime: string;
}

export interface AirTableJSON {
  records: AirTableRecord[];
}

export interface AirTableData {
  tableName: string;
  airTableJSON: AirTableJSON;
}

export const initialAirTableRecord: AirTableRecord = {
  id: "",
  fields: {},
  createdTime: "",
};

export interface AirTableDocumentData {
  [key: string]: string;
}

export interface AirTableSelectedDocument {
  typeOfDocument: string;
  documentData: AirTableDocumentData;
}

export interface SelectedDocumentsProps {
  selectedDocuments: AirTableSelectedDocument[];
  setSelectedDocuments: React.Dispatch<React.SetStateAction<AirTableSelectedDocument[]>>;
}

export type ButtonType = "button" | "submit" | "reset" | undefined;

export interface SelectBoxOption {
  optionValue: string;
  optionContent: string;
}

export interface SelectBoxDetails {
  selectBoxName: string;
  selectListOfOptions: SelectBoxOption[];
}

export const initialSelectBoxDetails: SelectBoxDetails = {
  selectBoxName: "",
  selectListOfOptions: [],
};

export type StringStateSet = React.Dispatch<React.SetStateAction<string>>;

export interface SearchSuggestionInput {
  abbreviatedName?: string;
  address?: string;
  category?: string;
  city?: string;
  country?: string;
  email?: string;
  id?: string;
  internationalName?: string;
  isoCountryCode?: string;
  mainActivity?: string;
  metropolitanArea?: string;
  name?: string;
  nationalLegalForm?: string;
  nativeName?: string;
  postcode?: string;
  standardisedLegalForm?: string;
  state?: string;
  type?: string;
  website?: string;
}

export interface SearchSuggestionResult {
  id: string;
  name: string;
  iso?: string;
  isGuo?: boolean;
  type?: string;
  notInNeo?: boolean;
}

export interface SearchInput {
  companyCategory?: string;
  isoCountry?: string;
  entityType?: string;
  legalForm?: string;
  mainActivity?: string;
  address?: string;
  city?: string;
  country?: string;
  county?: string;
  maxResult?: string;
  metropolitanArea?: string;
  stateOrProvince?: string;
  email?: string;
  abbreviatedName?: string;
  entityName?: string;
  personName?: string;
  postcode?: string;
  website?: string;
}

export interface SearchInputField {
  type: string;
  placeholder: string;
  name: string;
  autoComplete: string;
}

export interface HttpHeaders {
  [name: string]: string;
}

export interface ImportVariables {
  inputLocation: string;
  inputBucket: string;
  inputMount: string;
  intermediatePath: string;
  outputLocation: string;
  outputBucket: string;
  outputMount: string;
  tmpdir: string;
  zipPath: string;
  aspectPath: string;
  aspectExtension: string;
  sortedAspects: string;
  fingerPrintFilePath: string;
  fullFingerPrintFile: string;
  progressCount: string;
  entityMod: string;
  entityListFilePath: string;
  entityZipPath: string;
  entityLocalPath: string;
  reverseIdFile: string;
  newReverseIdFile: string;
  partitionToGit: string;
  partitionFromGit: string;
  entityCacheLocation: string;
  entityCacheBucket: string;
  entityCacheMount: string;
  [name: string]: string;
}

export const EmptyImportVariablesObject = {
  inputLocation: "",
  inputBucket: "",
  inputMount: "",
  intermediatePath: "",
  outputLocation: "",
  outputBucket: "",
  outputMount: "",
  tmpdir: "",
  zipPath: "",
  aspectPath: "",
  aspectExtension: "",
  sortedAspects: "",
  fingerPrintFilePath: "",
  fullFingerPrintFile: "",
  progressCount: "",
  entityMod: "",
  entityListFilePath: "",
  entityZipPath: "",
  entityLocalPath: "",
  reverseIdFile: "",
  newReverseIdFile: "",
  partitionToGit: "",
  partitionFromGit: "",
  entityCacheLocation: "",
  entityCacheBucket: "",
  entityCacheMount: "",
};

export interface FileDetails {
  id: string;
  shaOfTruncatedId: string;
  parts: string[];
  name: string;
  intermediateEntitylistPath: string;
  entitylistTarPath: string;
  entitylistTarOnGoogleCloud: string;
  pathInsideTar: string;
  cachedEntityPath: string;
}

export interface FieldData {
  [aspect: string]: ParkingFromAspect;
}

export interface ParkingFromAspect {
  [parking: string]: string;
}

export interface ArrayData {
  [parkingArrayName: string]: ParkingFromAspectArray;
}

export interface ParkingFromAspectArray {
  array: string;
  fields: ParkingFromAspect;
}

export interface ValetParkingRules {
  [valetLocation: string]: ValetParkingRule;
}

export interface ValetParkingRule {
  fields?: FieldData;
  arrays?: ArrayData;
}

export interface NameValue {
  [name: string]: string;
}

export interface ValetLocationData {
  [name: string]: string | NameValue[];
}

export interface ValetLocations {
  [valetLocation: string]: ValetLocationData;
}

export type ValetParking = (
  rules: ValetParkingRules,
  debug?: boolean,
) => (p: ObjectType) => ObjectType;

export interface BufferAndLoadUrlData {
  buffer: ArrayBuffer;
  fileDetails: FileDetails;
  metadataForDebug: string[];
  url: string[];
}

export interface RowNumberAndLines {
  row: string;
  rowName: string;
  lines: string[];
}

export interface NamesAndValues {
  rowName: string;
  names: string[];
  values: string[][];
}

export interface Entity {
  entityListAndLoadUrlData: BufferAndLoadUrlData;
  rawPartyDetails: PartyDetails;
  partyDetails: PartyDetails;
  rowNumberAndLines: RowNumberAndLines[];
  namesAndValues: NamesAndValues[];
}

export interface FingerPrint {
  lines: string[];
  fileNames: string[];
}

export interface ReduceAccumulator {
  currentHeader: number;
  currentLines: string[];
  result: RowNumberAndLines[];
}

export interface AllSameValues<V> {
  [name: string]: V;
}

export interface AspectDetailsTransformDefn {
  aspectKeyFilter: (name: string) => boolean;
  aspectKeyMap: (name: string) => string;
}

export type AspectTransform = (
  defn: AspectDetailsTransformDefn,
) => (p: AspectDetails) => AspectDetails;

export interface PartyDetailsTransformDefn extends AspectDetailsTransformDefn {
  partyKeyFilter: (name: string) => boolean;
  partyKeyMap: (name: string) => string;
}

export interface ArrayType {
  name: string;
  no_bo: any[];
  bo: any[];
}

export type TitleProps = {
  text: string;
  isOpen: boolean;
  hideIcon?: boolean;
  light?: boolean;
  toggle?: () => void;
  small?: boolean;
};

export interface AccordionProps {
  title: string;
  children: React.ReactChild;
  opened?: boolean;
  dep?: string;
  wrapper?: any;
}

export interface Coordinates {
  latitude: number;
  longitude: number;
}

// custom interface for basic examples
export interface ColumnDetails {
  [key: string]: string;
}

// interface for the filterable table headers
export interface ColumnHeader {
  Header: string; // it's with a capital H because React Table wants it with capital H
  accessor: string;
  disableSortBy?: boolean;
  disableFilters?: boolean;
}

// interface for each specific header configuration
export interface HeaderConfiguration {
  [key: string]: string | boolean;
}

export interface DataFormatConfiguration {
  accessorName: string;
  format: string;
}

// interface for each table headers configuration
export interface TableConfiguration {
  name: string;
  columnDetails: HeaderConfiguration[];
  additionalTableOptions?: any;
  dataFormatConfiguration?: DataFormatConfiguration[];
}

export interface DiDoMenuItem {
  text: string;
  icon: string;
  link: string;
}

export type ClickDiDoMenuItem = React.Dispatch<DiDoMenuItem>;
export type ChangeDiDoMenuTab = React.Dispatch<string>;

export interface AdminMenuItem {
  text: string;
  icon: string;
  link: string;
}

export type ClickAdminMenuItem = React.Dispatch<AdminMenuItem>;
export type ChangeAdminMenuTab = React.Dispatch<string>;

export interface HubwiseDashboardMenuItem {
  text: string;
  icon: string;
  link: string;
}

export type ClickHubwiseDashboardMenuItem = React.Dispatch<HubwiseDashboardMenuItem>;
export type ChangeHubwiseDashboardMenuTab = React.Dispatch<string>;

export interface InteractiveDataItem {
  name: string;
  iconURL: string;
  prototypeURL: string;
}

export const emptyInteractiveDataItem: InteractiveDataItem = {
  name: "",
  iconURL: "",
  prototypeURL: "",
};

export interface IFrameSize {
  width: string;
  height: string;
}

export const emptyIFrameSize: IFrameSize = {
  width: "",
  height: "",
};

export type ColorMode = "light" | "dark";

export interface NotificationsData {
  Notifications: Notifications[];
}

export interface Notifications {
  name: string;
  messageContent: string;
  timeStamp: string;
}

export interface UserProfileProps {
  headerStyle: HeaderStyle;
  tabIsActive: boolean;
  alertIsActive: boolean;
}

export interface DataProps {
  id: string;
  createdAt: string;
  email: string;
  party?: string;
  status: string;
  fields: string[];
  streamId: string;
  chatName: string;
  chats: any[];
}

export interface CcIdListProps {
  id: string;
  createdAt: string;
  createdBy: string;
  documents: number;
  status: string;
}

export interface InfoCardProps {
  icon: string;
  num: number;
  name: string;
}

export interface CardsDataProps {
  num: number;
  data: string | object;
}

export interface InfoCardDataProps {
  icon: string;
  num: number;
  name: string;
}

export interface TransactionProps {
  img: string;
  color: string;
  data: number;
  text?: string;
}

export interface Filter {
  search: string;
  date: string;
  status: string;
}

export type PaginationProps = {
  limit: number;
  total: number;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
};

export interface TopHoldingType {
  "Data Date": string;
  "Largest Holding": string;
  Percentage: string;
  Rank: string;
}

export interface PerformanceType {
  "Annual Performance 12 Months": string;
  "Annual Performance 12 to 24 Months": string;
  "Annual Performance 24 to 36 Months": string;
  "Annual Performance 36 to 48 Months": string;
  "Annual Performance 48 to 60 Months": string;
  "Annual Performance YTD": string;
  "Discrete Performance 0 to 12 Months": string;
  "Discrete Performance 1 Month": string;
  "Discrete Performance 3 Months": string;
  "Discrete Performance 6 Months": string;
  "Discrete Performance 12 Months": string;
  "Discrete Performance 12 to 24 Months": string;
  "Discrete Performance 24 Months": string;
  "Discrete Performance 24 to 36 Months": string;
  "Discrete Performance 36 Months": string;
  "Discrete Performance 36 to 48 Months": string;
  "Discrete Performance 48 to 60 Months": string;
  "Discrete Performance 60 Months": string;
  "Discrete Performance 120 Months": string;
}

export type ObjectType = { [key: string]: any };

export interface ItemsPortfolioBreakdown {
  "Advisor Fund Index": string;
  "Alpha Manager": string;
  "Annual Performance 12 Months": string;
  "Annual Performance 12 to 24 Months": string;
  "Annual Performance 24 to 36 Months": string;
  "Annual Performance 36 to 48 Months": string;
  "Annual Performance 48 to 60 Months": string;
  "Annual Performance YTD": string;
  "Asset Class": string;
  "Asset Class BreakDown": string;
  "Asset Class Title": string;
  "Child Fund ID": string;
  Citicode: string;
  "Client Scope Id": string;
  "Crown Rating": string;
  Currency: string;
  "Discrete Performance 0 to 12 Months": string;
  "Discrete Performance 1 Month": string;
  "Discrete Performance 3 Months": string;
  "Discrete Performance 6 Months": string;
  "Discrete Performance 12 Months": string;
  "Discrete Performance 12 to 24 Months": string;
  "Discrete Performance 24 Months": string;
  "Discrete Performance 24 to 36 Months": string;
  "Discrete Performance 36 Months": string;
  "Discrete Performance 36 to 48 Months": string;
  "Discrete Performance 48 to 60 Months": string;
  "Discrete Performance 60 Months": string;
  "Discrete Performance 120 Months": string;
  "Entity Group Name": string;
  "Entity Name": string;
  "Fund ID": string;
  "Fund Level": string;
  "Fund Manager": string;
  "Fund Manager - Fund": string;
  "Fund Manager - URL": string;
  "Fund Manager Details": string;
  "Fund Name": string;
  "Fund Type": string;
  "Fund URL": string;
  "Group Fund ID": string;
  "Group Name": string;
  "Group Url": string;
  ISIN: string;
  "Legal Entity Identifier": string;
  "Morning Star Analyst Rating": string;
  "Parent Fund ID": string;
  "Portfolio Asset Class": string;
  "Portfolio Sector": string;
  Price: string;
  "Ratio Alpha": string;
  "Ratio Beta": string;
  "Ratio Sharpe": string;
  "Ratio Volatility": string;
  "Region Breakdown": string;
  "Risk Score": string;
  SEDOL: string;
  "Sector Breakdown": string;
  TIDM: string;
  "Top Holdings": string;
  Yield: string;
}

export interface PortfolioBreakdownData {
  data: ItemsPortfolioBreakdown;
}

export interface ElementPortfolioBreakdown {
  Asset: string;
  "Data date": string;
  Percentage: string;
  Rank: string;
}

export interface KeyPeopleData {
  DMC: IndividualPersonData[];
  DMC_BO: IndividualPersonData[];
}

export interface IndividualPersonData {
  Address: string;
  Age: string;
  "Age Bracket": string;
  "Also a ShareHolder?": string;
  "Appointment Date": string;
  Biography: string;
  "Board, Committee or Dept": string;
  "Confirmation Date": string;
  Country: string;
  "Country of Nationality": string;
  "Date Info Last Received": string;
  "Date of Birth": string;
  "First Name": string;
  "Full Name": string;
  "Function Label (Debug)": string;
  "Function Code (Debug)": string;
  Gender: string;
  "Individual or Company": string;
  "Information Provider": string;
  "Information Source": string;
  "Last Name": string;
  "Level of responsibility": string;
  "Middle Name": string;
  Position: string;
  "Resignation Date": string;
  Salutation: string;
  Title: string;
  "Type of position": string;
  "UCI (Unique Contact Identifier)": string;
}

export interface ContactInfoLEIIdentifer {
  "LEI (Legal Entity Identifer)": string | undefined;
}

export type ContactInfoArray = [string, ContactInfoLEIIdentifer[]];

export type ContactInfoData = [string, string | ContactInfoArray][];

export interface HistoryName {
  "Name change date": string;
  "Previous Name": string;
  "Previous Name (international language)": string | undefined;
}

export type HistoryArray = [string, HistoryName[]];

export type HistoryData = [string, string | HistoryArray][];

export interface IdentifiersIDs {
  "European VAT number": string | undefined;
  "IP Identification Label": string | undefined;
  "IP identification number": string | undefined;
  "ISIN Number": string | undefined;
  "LEI (Legal Entity Identifier)": string | undefined;
  "National ID Label": string | undefined;
  "National ID Number": string | undefined;
  "National ID Type": string | undefined;
  "Other company ID number": string | undefined;
  "Statistical number": string | undefined;
  "Ticker Symbol": string | undefined;
  "Trade register number": string | undefined;
  "VAT/Tax number": string | undefined;
}

export interface IdentifiersData {
  "GIIN (Global Intermediary Identification Number)": string;
  IDs: IdentifiersIDs[];
  IDs_BO: IdentifiersIDs[];
}

export type IdentifiersArrays = [string, IdentifiersIDs[]];

export type IdentifiersFilteredData = [string, IdentifiersArrays][];

export interface SingleFund {
  "Asset Class Title": string;
  Citicode: string;
  Currency: string;
  "Fund ID": string;
  "Fund Name": string;
  "Fund Type": string;
  ISIN: string;
  "Legal Entity Identifier": string;
  "Portfolio Asset Class": string;
  "Portfolio Sector": string;
  SEDOL: string;
}

export interface TrackActivityData {
  activity: string;
  country: string | undefined;
  currentPage: string | undefined;
  date_and_time: string;
  id: string;
  region: string | undefined;
  timezone: string | undefined;
  user: UserDetailsOkta | undefined;
}

export interface JsonData {
  meta: { id: number; type: string; deleted: boolean }[];
  row: { iso: string; name: string; id: string }[];
}

export interface Results {
  columns: [string];
  data: JsonData[];
}

export type ResultsArray = [Results];

export interface Neo4jResultJson {
  commit: string;
  errors: any[];
  results: ResultsArray;
  transaction: { expires: string };
}

export interface GeoCoderAddress {
  city: string;
  countryCode?: string;
  countryName?: string;
  county?: string;
  countyCode?: string;
  district?: string;
  houseNumber?: string;
  label?: string;
  postalCode?: string;
  state?: string;
  street?: string;
}

export interface GeoCoderItem {
  access: [{ lat: number; lng: number }];
  address: GeoCoderAddress;
  houseNumberType: string;
  mapView: { west: number; south: number; east: number; north: number };
  position: { lat: number; lng: number };
  resultType: string;
  scoring: {
    fieldScore: {
      city: number;
      country: number;
      houseNumber: number;
      postalCode: number;
      streets: number[];
    };
    queryScore: number;
  };
  title: string;
}

export interface Identifiers {
  "": string;
  "European VAT number": string;
  "IP identification label": string;
  "IP identification number": string;
  "ISIN number": string;
  "LEI (Legal Entity Identifier)": string;
  "National ID label": string;
  "National ID number": string;
  "National ID type": string;
  "Other company ID number": string;
  "Statistical number": string;
  "Ticker symbol": string;
  "Trade register number": string;
  "VAT/Tax number": string;
  "BvD ID number": string;
}

export interface EntityData {
  "Accounting practice": string;
  "Added value": string;
  "Audit status": string;
  "Average cost of employee (th)": string;
  Capital: string;
  "Cash & cash equivalent": string;
  "Cash flow": string;
  "Cash flow / Operating revenue (%)": string;
  "Closing date": string;
  "Collection period (days)": string;
  "Consolidation code": string;
  "Costs of employees": string;
  "Costs of employees / Operating revenue (%)": string;
  "Costs of goods sold": string;
  "Credit period (days)": string;
  Creditors: string;
  "Current assets": string;
  "Current liabilities": string;
  "Current ratio (x)": string;
  Debtors: string;
  "Depreciation & Amortization": string;
  "EBIT margin (%)": string;
  EBITDA: string;
  "EBITDA margin (%)": string;
  "Enterprise value": string;
  "Enterprise value / EBITDA (x)": string;
  "Estimated employees": string;
  "Estimated operating revenue": string;
  "Exchange rate from original currency": string;
  "Export revenue": string;
  "Export revenue / Operating revenue (%)": string;
  "Extr. and other P/L": string;
  "Extr. and other expenses": string;
  "Extr. and other revenue": string;
  "Filing type": string;
  "Financial P/L": string;
  "Financial expenses": string;
  "Financial revenue": string;
  "Fixed assets": string;
  "Gearing (%)": string;
  "Gross margin (%)": string;
  "Gross profit": string;
  "Intangible fixed assets": string;
  "Interest cover (x)": string;
  "Interest paid": string;
  "Liquidity ratio (x)": string;
  Loans: string;
  "Long term debt": string;
  "Market cap / Cash flow from operations (x)": string;
  "Material costs": string;
  "Net assets turnover (x)": string;
  "Net current assets": string;
  "Non-current liabilities": string;
  "Number of employees": string;
  "Number of months": string;
  "Operating P/L [=EBIT]": string;
  "Operating revenue (Turnover)": string;
  "Operating revenue per employee (th)": string;
  "Original currency": string;
  "Original units": string;
  "Other current assets": string;
  "Other current liabilities": string;
  "Other fixed assets": string;
  "Other non-current liabilities": string;
  "Other operating expenses": string;
  "Other shareholders funds": string;
  "P/L after tax": string;
  "P/L before tax": string;
  "P/L for period [=Net income]": string;
  "Profit margin (%)": string;
  "Profit per employee (th)": string;
  Provisions: string;
  "R&D expenses / Operating revenue (%)": string;
  "ROA using Net income (%)": string;
  "ROA using P/L before tax (%)": string;
  "ROCE using Net income (%)": string;
  "ROCE using P/L before tax (%)": string;
  "ROE using Net income (%)": string;
  "ROE using P/L before tax (%)": string;
  "Research & Development expenses": string;
  Sales: string;
  "Shareholders funds": string;
  "Shareholders funds per employee (th)": string;
  "Shareholders liquidity ratio (x)": string;
  "Solvency ratio (Asset based) (%)": string;
  "Solvency ratio (Liability based) (%)": string;
  "Source (for publicly quoted companies)": string;
  Stock: string;
  "Stock turnover (x)": string;
  "Tangible fixed assets": string;
  Taxation: string;
  "Total assets": string;
  "Total assets per employee (th)": string;
  "Total shareh. funds & liab.": string;
  "Working capital": string;
  "Working capital per employee (th)": string;
  "﻿BvD ID number": string;
}
