// Copyright Marco Rapaccini and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.

import { MouseEvent } from "react";
import styled from "styled-components";
import { CustomDataSelector } from "styles/SectionSharedItems.styled";
import {
  AirTableSelectedDocument,
  SelectBoxDetails,
  SelectBoxOption,
  SelectedDocumentsProps,
} from "../../../types";

/**
 * Component that renders a select box with custom options.
 * @param selectBoxDetails: an object that represents the name of the select and the list of options for the user
 * @param selectedDocumentsProps: state and setter for selectedDocuments
 */
export function DataSelector({
  selectBoxDetails,
  selectedDocumentsProps,
}: {
  selectBoxDetails: SelectBoxDetails;
  selectedDocumentsProps: SelectedDocumentsProps;
}) {
  const handleSelection = (event: MouseEvent<HTMLButtonElement>) => {
    const newSelectedDocument: AirTableSelectedDocument = {
      typeOfDocument: selectBoxDetails.selectBoxName,
      documentData: JSON.parse(event.currentTarget.value),
    };
    selectedDocumentsProps.setSelectedDocuments((prevState) => [...prevState, newSelectedDocument]);
  };

  return (
    <CustomDataSelector id={selectBoxDetails.selectBoxName}>
      {selectBoxDetails.selectListOfOptions.map((selectBoxOption: SelectBoxOption) => {
        return (
          <button
            type="button"
            key={selectBoxOption.optionValue}
            onClick={handleSelection}
            value={selectBoxOption.optionValue}
          >
            {selectBoxOption.optionContent}
          </button>
        );
      })}
    </CustomDataSelector>
  );
}
