import styled, { keyframes } from "styled-components";

// animation for fade in
export const fadeIn = keyframes`
     from {
         opacity:0;
     }
     to {
         opacity:1;
     }
 `;

export const slideLeft = keyframes`
    100% { left: 0%; }
`;

export const ModalOverlay = styled.div`
  color: #4a82a6;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1100;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
`;

export const ModalBoxContent = styled.div<{ left?: boolean }>`
  position: fixed;
  display: block;
  left: ${({ left }) => (left ? "-100%" : "0%")};
  height: 100%;
  margin: auto;
  width: 33.33%;
  transition: 2s ease-in-out;
  border: none;
  pointer-events: visible;
  background-color: ${({ theme }) => theme.colors.WHITE};
  box-shadow: ${({ theme }) => `0px 0px 4px ${theme.colors.SHADOW[200]}`};
  animation: ${(props) => (props.left ? slideLeft : fadeIn)} 0.5s forwards;

  -moz-animation: ${(props) => (props.left ? slideLeft : fadeIn)} 0.5s forwards; /* Firefox */
  -webkit-animation: ${(props) => (props.left ? slideLeft : fadeIn)} 0.5s forwards; /* Safari and Chrome */
  -o-animation: ${(props) => (props.left ? slideLeft : fadeIn)} 0.5s forwards; /* Opera */
  @media (max-width: 768px) {
    width: 70%;
    left: 50%;
    transform: translate(-50%, 0);
  }
  @media (max-width: 500px) {
    width: 90%;
  }
`;

export const ModalBoxContainer = styled.div`
  width: 100vw;
  top: 74px;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 1200;
  pointer-events: none;
`;
export const ModalTitleWrapper = styled.div`
  margin: 40px 32px -5px 32px;
`;

export const ModalBoxControls = styled.div`
  position: absolute;
  top: 24px;
  right: 20px;
  z-index: 1200;
`;
export const ModalBoxCloseIcon = styled.span`
  display: inline-block;
  vertical-align: middle;
  width: 32px;
  height: 32px;
  background-image: url(${`${process.env.PUBLIC_URL}/assets/closeAccordionIcon2.svg`});
  background-color: white;
  border-radius: 30px;
  background-size: 32px;
  cursor: pointer;
`;

export const NonScrollableContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ScrollableContent = styled.div`
  position: absolute;
  width: auto;
  height: calc(100% - 140px);
  overflow-y: auto;
  margin: 10px 32px 30px 32px;

  /* scroll bar width */
  &::-webkit-scrollbar {
    //width: 10px;
    display: none;
  }

  /* scroll bar track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px #333;
    border-radius: 10px;
  }

  /* scroll bar handle */
  &::-webkit-scrollbar-thumb {
    background: rgba(51, 51, 51, 0.8);
    opacity: 0.6;
    border-radius: 10px;
  }

  /* scroll bar handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(51, 51, 51, 1);
  }
`;

export const FullName = styled.div`
  display: block;
  color: ${({ theme }) => theme.colors.DARK[400]};
  font-size: 20px;
  line-height: 20px;
  font-weight: 700;
  font-family: Roboto Condensed;
`;

export const DateOfBirth = styled.div`
  display: inline-block;
  margin-top: 20px;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.PRIMARY[400]};
  font-weight: 500;
  font-family: Roboto Condensed;
`;

export const Country = styled.div`
  display: inline-block;
  margin-left: 10px;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.PRIMARY[400]};
  font-weight: 500;
  font-family: Roboto Condensed;
`;

export const Biography = styled.div`
  margin: 0 0 30px 0;
  font-size: 14px;
  line-height: 20px;
  padding-top: 20px;
  color: ${({ theme }) => theme.colors.PRIMARY[400]};
  font-family: Roboto Condensed;
  & > div {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0px;
    }
  }
`;

export const Positions = styled.div`
  margin: 0px 20px 5px 0px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.PRIMARY[400]};
`;

export const SinglePosition = styled.div``;
