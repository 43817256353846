// Copyright Marcello Di Fronzo Gravallese and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.

import IframeCard from "./EcpIframeCard";
import { IframeCardDetails } from "../../types/ecp";

function IframeDivBasic() {
  const iframeCardDetails: IframeCardDetails[] = [
    {
      streamId: "WbpHBL0o1GNNVBZIUIMRoH///nnXpQu6dA==",
      chatName: "first",
    },
    {
      streamId: "zhJ1rZJVJVVAcsoWyzwEy3///nnXpgJbdA==",
      chatName: "second",
    },
  ];

  return (
    <>
      <h1>ECP Test Page</h1>
      {iframeCardDetails.map((item, i) => (
        <IframeCard key={i} streamId={item.streamId} chatName={item.chatName} />
      ))}
    </>
  );
}

export default IframeDivBasic;
