import styled from "styled-components";

export const CustomSelectBox = styled.select`
  box-sizing: border-box;
  width: 100%;
  height: 48px;
  background-color: #ffffff;
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid rgba(128, 128, 128, 0.5);
`;
