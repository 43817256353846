import styled from "styled-components";

export const Container = styled.div<{ noTopBorder?: boolean; borderBottom?: boolean }>`
  padding: 24px 0;
  margin-bottom: 12px;
  border-top: ${({ noTopBorder, theme }) => !noTopBorder && theme.colors.ACCORDION[100]};
  border-bottom: ${({ borderBottom }) => borderBottom && "2px solid #36407380"};
`;

export const Content = styled.div<{ noPaddingTop?: boolean }>`
  width: 100%;
  height: 100%;
  padding-top: ${({ noPaddingTop }) => (noPaddingTop ? "" : "8px")};
`;
