import React, { FunctionComponent, useEffect, useState } from "react";
import { DataProps } from "types/index";
import { ModalController } from "hooks/useModal";
import { format } from "date-fns";
import IframeCard from "components/ecp/EcpIframeCard";
import {
  Container,
  Table,
  Th,
  Thead,
  Tr,
  Sn,
  Tbody,
  Td,
  Status,
  Dot,
  PaginationContainer,
  PaginationButton,
  PaginationPosition,
  InnerTd,
  InnerContent1,
  SpanWrapper,
  EmptySpan,
  TableDate,
  TableTime,
  NoResult,
  Extra,
  ExtraButton,
} from "../../../styles/admin/AdminTable.styled";

const AdminTable: FunctionComponent<{
  data: DataProps[];
  isParty?: boolean;
}> = ({ data, isParty = true }) => {
  const [dataToDisplay, setDataToDisplay] = useState<DataProps[]>([]);
  const [selectedIndex, setSelectedIndex] = useState("");
  const [streamId, setStreamId] = useState("");
  const [chatName, setChatName] = useState("");

  const close = () => {
    setSelectedIndex("");
    setChatName("");
  };

  const singleData = dataToDisplay.find((el) => el.id === selectedIndex);

  const toggleVisibility = (index: string, streamId: string, chatName: string) => {
    if (selectedIndex === index) {
      close();
    } else {
      setSelectedIndex(index);
      setStreamId(streamId);
      setChatName(chatName);
    }
  };

  useEffect(() => {
    if (selectedIndex) {
      const filteredData = dataToDisplay.filter((data) => data.id === selectedIndex);
      setDataToDisplay(filteredData);
    } else {
      setDataToDisplay(data);
    }
  }, [selectedIndex, data]);

  return (
    <Container>
      {!selectedIndex ? (
        <Table>
          <Thead>
            <Tr>
              <Th>
                <Sn>SN</Sn>Created
              </Th>
              {isParty && <Th>Party</Th>}
              <Th>Work Email</Th>
              <Th>Status</Th>
            </Tr>
          </Thead>
          <Tbody hide={dataToDisplay?.length === 0 || !dataToDisplay}>
            {dataToDisplay.map((item, index) => (
              <React.Fragment key={item.id}>
                <Tr
                  body
                  onClick={() => toggleVisibility(item.id, item.streamId, item.chatName)}
                  data-testid="row"
                >
                  <Td>
                    <EmptySpan>
                      <Sn>{selectedIndex || index + 1}</Sn>
                      <SpanWrapper>
                        <TableDate>
                          {format(new Date(item.createdAt), "dd MMM yyy")}
                          <br />
                        </TableDate>
                        <TableTime>{format(new Date(item.createdAt), "HH:mm")}</TableTime>
                      </SpanWrapper>
                    </EmptySpan>
                  </Td>
                  {isParty && <Td>{item.party}</Td>}
                  <Td>{item.email}</Td>
                  <Td>
                    <Status status={item.status}>
                      {item.status === "ACTIVE" && <Dot />}
                      {item.status}
                    </Status>
                  </Td>
                </Tr>
              </React.Fragment>
            ))}

            {!selectedIndex ? (
              <Tr>
                <Td colSpan={4}>
                  <PaginationContainer>
                    <PaginationButton>Previous</PaginationButton>
                    <PaginationPosition>Page 1 of 10</PaginationPosition>
                    <PaginationButton>Next</PaginationButton>
                  </PaginationContainer>
                </Td>
              </Tr>
            ) : null}
          </Tbody>
        </Table>
      ) : null}
      {selectedIndex === singleData?.id && (
        <Extra data-testid="extra-data">
          <div>
            <InnerContent1>
              <div>
                {singleData?.fields.map((f, index) => <InnerTd key={index}>{`${f} >`}</InnerTd>)}
              </div>
              <ExtraButton onClick={close}>Close</ExtraButton>
            </InnerContent1>
          </div>
        </Extra>
      )}
      {streamId ? <IframeCard streamId={streamId} chatName={chatName} /> : null}
      <NoResult show={dataToDisplay?.length === 0 || !dataToDisplay}>
        No record found to display
      </NoResult>
    </Container>
  );
};

export default AdminTable;
