import { keyframes } from "styled-components";
import styled from "styled-components";

export const ssncVideo = `${process.env.PUBLIC_URL}/assets/ssncVideo.mov`;
export const ssncLogo = `${process.env.PUBLIC_URL}/assets/ssnc-logo.png`;

export const SSnCLandingPageMenuContainer = styled.div`
  height: 100vh;
  width: 100vw;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: fill;  
  overflow: hidden;
`;

export const SSnCLandingPageMenuMain = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
`;

export const VideoHolder = styled.div`
  margin: 0;
  overflow: hidden;
  display: flex;
  & video {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    overflow: hidden;
  }
`;

export const SSnCLogoHolder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const fadeInAnimation = keyframes `
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }

`

export const LogoAndTextHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100vw;
  margin: auto;
  opacity: 0;
  animation: ${fadeInAnimation} 1.5s ease-in-out forwards;

  & h1 {
    text-align: center;
    color: #364073;
    font-size: 36px;
    line-height: 42px;
    width: 700px;
  }

  & img {
    width: 700px;
    height: 500px;
  }
`;