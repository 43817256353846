export enum TRACKER {
  SIGNOUT_PAGE = "signout page",
  CROWN_ALERT_MODAL = "crown alert modal",
  CROWN_ALERT_BUTTON = "crown alert button",
  NAVIGATION_HEADER = "header",
  CROWN_ALERT_MODAL_OPENED = "crown alert modal opened",
  CROWN_ALERT_MODAL_CLOSED = "crown alert modal closed",
  ALERT_MODAL = "alert modal",
  FEEDBACK_BUTTON_CLICKED = "Feedback button clicked",
  DATA_ENQUIRY_BUTTON = "Data Enquiry button clicked",
  DIDO_DEMO_INTERACTIVE = "dido demo interactive",
  DIDO_DEMO_CLIENT = "dido demo client admin room",
  BASIC_SEARCH = "basic search",
  IDENTIFIER_SEARCH = "identifier search",
  USER_PROFILE_OPEN = "user profile header opened",
  USER_PROFILE_CLOSE = "user profile header closed",
  NOTIFICATION_BUTTON = "notification button",
  NOTIFICATION_OPEN = "Notification Bar Opened",
  NOTIFICATION_CLOSED = "notification bar closed",
  PARTY_PROFILE = "party profile",
  PARTY_PROFILE_AFME = "party profile afme",
  PARTY_PROFILE_CASH = "party profile cash",
  PARTY_PROFILE_SUB_FUNDS = "Party Profile Sub Funds",
  SUB_FUNDS_CLICKED = "Sub Funds Table Row Clicked",
  FUNDS = "Party Profile Funds",
  FUNDS_TABLE_CLICKED = "Funds Table Row Clicked",
  PARTY_PROFILE_PAYMENT = "Party Profile Payment",
  ALICE_REQUEST = "alice request",
  ALICE_ADMIN_DASHBOARD = "Alice Admin Dashboard",
  ENTITY_LINK = "Alice Admin Entity Link",
  GET_SEARCH = "Alice Admin Get Search",
  ALICE_ADMIN_SETTINGS = "Alice Admin Settings",
  ALERT = "Alert",
  CLIENT_ALICE_REQUEST = "Client Alice Requests",
  CLIENT_T360_CONNECT = "Client T360 Connect",
  CLIENT_REPORTS = "Client Reports",
  CLIENT_SETTINGS = "Client Settings",
  FUNDS_SEARCH_RESULTS = "Funds Search Results",
  SEARCH_PAGE = "Search",
  HOME_PAGE = "Home",
  SEARCH_RESULTS = "Search Results",
  HUBWISE_LANDING_PAGE = "Hubwise Landing Page",
  HUBWISE_DASHBOARD = "Hubwise Dashboard",
  HUBWISE_DASHBOARD_PROFILES= "Hubwise Profiles in Dashboard",
  HUBWISE_DASHBOARD_REQUESTS = "Hubwise Requests in Dashboard",
  CLIENT_REQUEST_TEMPLATE= "Client Request Template"
}
