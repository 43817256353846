// Copyright Marco Rapaccini and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.

/**
 * This is the dedicated component for the first accordion of a Profile in Party Manager, that we call Contact Info
 */

import { NoDataToDisplay } from "components/noData/ComingSoon";
import { AspectDetails, ContactInfoArray, ContactInfoData, ContactInfoLEIIdentifer } from "types";
import {
  ContactInfoContainer,
  ContactInfoContent,
  ContactInfoCustomFGC,
  MapBoxContainer,
  ContactInfoCustomFV,
  Title,
  SingleRow,
  FieldName,
  FieldGroupsCover,
} from "styles/crownAlert/ContactInfo.styled";
import { ContactInformation } from "types/party";
import AliceModalTooltip from "components/AliceModalTooltip";
import { dataFilter } from "../dataFilter/DataFilter";
import { LeafletMap } from "./leafletMap/LeafletMap";

function SingleItem({ name, field }: { name: string; field: string }) {
  // remove all the part after the pipe | if there's the pipe in the Region in Country field
  if (name === "Region in Country" && field.indexOf("|") > 0)
    field = field.substring(0, field.indexOf("|"));

  if (name === "Name" || name === "Address Line 1" || name === "City") field = field.toLowerCase();

  return (
    <SingleRow>
      <FieldName>{name}</FieldName>
      <AliceModalTooltip selectedField={field} selectedKey={name}>
        <ContactInfoCustomFV
          capitaliseFirstLetter={
            name === "Name" || name === "Address Line 1" || name === "City" ? 1 : 0
          }
        >
          {name === "Website" ? (
            <a href={`https://${field}`} target="_blank" rel="noreferrer">
              {field}
            </a>
          ) : (
            field
          )}
        </ContactInfoCustomFV>
      </AliceModalTooltip>
    </SingleRow>
  );
}

function FieldsGroup({ data }: { data: ContactInfoData }) {
  return (
    <ContactInfoCustomFGC>
      {data.length === 0 ? (
        <NoDataToDisplay />
      ) : (
        data.map(([name, field]: [string, string | ContactInfoArray | []], j: number) =>
          // check if data is an array
          Array.isArray(field) ? ( // this part is hardcoded, needs to be changed in the future
            field.length !== 0 ? (
              field.map(
                (
                  item: string | ContactInfoLEIIdentifer[], // if we have an array, we display it as simple fields
                ) =>
                  Object.entries(item).map(
                    ([fieldName, fieldValue]: [string, string], i: number) =>
                      fieldValue && fieldValue.trim() !== "" ? (
                        <SingleItem key={i} name={fieldName} field={fieldValue} />
                      ) : null,
                  ),
              )
            ) : null
          ) : field && field.trim() !== "" ? (
            <SingleItem key={j} name={name} field={field} />
          ) : null,
        )
      )}
    </ContactInfoCustomFGC>
  );
}

export function ContactInfo({
  data,
  showMap = true,
  hideTitle = false,
  column = false,
}: {
  data?: ContactInformation;
  showMap?: boolean;
  hideTitle?: boolean;
  column?: boolean;
}) {
  let address = "";
  let mappingIsFinished = false;

  // function for retrieving the address starting from 4 key fields in HeadOffice
  const retrieveAddress = () => {
    if (data && Object.entries(data)?.length !== 0) {
      Object.entries(data).forEach(([name, field]: [string, string | ContactInfoArray], j) => {
        if (
          !Array.isArray(field) &&
          field &&
          field.trim() !== "" &&
          (name === "Address Line 1" ||
            name === "City" ||
            name === "Postcode" ||
            name === "Country")
        )
          address = `${address} ${field}`;

        // if mapping is finished
        if (j === Object.entries(data).length - 1) mappingIsFinished = true;
      });
    }
  };
  retrieveAddress();

  if (!data) return null;
  const contactData = dataFilter({ data }) || ([] as AspectDetails[]);
  const dataLength: number = contactData?.length || 0;
  return (
    <ContactInfoContainer>
      {!hideTitle && <Title>Contact Information</Title>}
      <ContactInfoContent>
        {/* Left column */}
        <FieldGroupsCover isFull={!showMap} column={column}>
          <FieldsGroup data={contactData?.slice(0, dataLength / 2)} />
          <FieldsGroup data={contactData?.slice(dataLength / 2, dataLength)} />
        </FieldGroupsCover>
        {/* Right column */}
        {showMap && (
          <MapBoxContainer>
            <LeafletMap query={mappingIsFinished ? address : "No_Address"} />
          </MapBoxContainer>
        )}
      </ContactInfoContent>
    </ContactInfoContainer>
  );
}
