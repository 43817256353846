import {
  createContext,
  Dispatch,
  FunctionComponent,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useOktaAuth } from "@okta/okta-react";
import { StatusProps } from "constants/index";
import { GeneralDetailsType } from "types/party";
import { GeneralDetailsType as FundsGeneralDetailsType } from "types/funds";
import Toast from "components/Toast";
import { getUserNameOkta } from "../services/okta/okta";

import { ColorMode, UserDetailsOkta } from "../types";
import { getItem, setItem } from "../utils/localStorage";
import { KEY } from "../constants/key";
import { COLOR_MODE } from "../constants";

const TOAST_INFO_INITIAL_DATA = {
  isOpen: false,
  message: "",
  status: StatusProps.ERROR,
};

export const CONTEXT_DATA = {
  user: null,
  setUser: () => null,
  status: COLOR_MODE.LIGHT,
  setStatus: () => "",
  token: "",
  setToken: () => "",
  opacity: 1,
  setOpacity: () => null,
  selectedParty: null,
  selectedFunds: null,
  setSelectedFunds: () => {},
  setSelectedParty: () => {},
  toastInfo: TOAST_INFO_INITIAL_DATA,
  setToastInfo: () => {},
};

// create AppContext
export const AppContext = createContext<{
  user: UserDetailsOkta | null;
  setUser: Dispatch<SetStateAction<UserDetailsOkta | null>>;
  status: ColorMode;
  setStatus: Dispatch<SetStateAction<ColorMode>>;
  token: string;
  setToken: Dispatch<SetStateAction<string>>;
  opacity: number;
  setOpacity: Dispatch<SetStateAction<number>>;
  selectedParty: GeneralDetailsType | null;
  setSelectedParty: Dispatch<SetStateAction<GeneralDetailsType | null>>;
  selectedFunds: FundsGeneralDetailsType | null;
  setSelectedFunds: Dispatch<SetStateAction<FundsGeneralDetailsType | null>>;
  toastInfo: typeof TOAST_INFO_INITIAL_DATA;
  setToastInfo: Dispatch<SetStateAction<typeof TOAST_INFO_INITIAL_DATA>>;
}>(CONTEXT_DATA);

function getInitialState() {
  const status = localStorage.getItem("status");
  return status ? JSON.parse(status) : "light";
}

// create AppProvider component with initial data set
export const AppProvider: FunctionComponent<PropsWithChildren<object>> = ({ children }) => {
  const [user, setUser] = useState<UserDetailsOkta | null>(null);
  const { oktaAuth, authState } = useOktaAuth();
  const [toastInfo, setToastInfo] = useState(TOAST_INFO_INITIAL_DATA);

  // save okta token
  const [token, setToken] = useState("");

  //   get user info from local storage
  const userInfo = useMemo(() => getItem<UserDetailsOkta>(KEY.USER), []);

  // used to manage the global variable that indicates the theme color
  const [status, setStatus] = useState<ColorMode>(getInitialState);

  // used to manage the brightness of the app
  const [opacity, setOpacity] = useState<number>(100);

  // used to get selected party in the alert page
  const [selectedParty, setSelectedParty] = useState<GeneralDetailsType | null>(null);

  // used to get selected funds in the alert page
  const [selectedFunds, setSelectedFunds] = useState<FundsGeneralDetailsType | null>(null);

  useEffect(() => {
    // change the brightness of the entire app (style applied to html tag)
    document.documentElement.style.filter = `brightness(${opacity / 2 + 50}%)`;

    // change the color theme of the html tag
    document.documentElement.style.backgroundColor =
      status === COLOR_MODE.LIGHT ? "#fff" : "#222425";

    // check if user info have been persisted already, then use that
    if (userInfo?.email) {
      return setUser(userInfo);
    }

    // use oktaAuth to fetch user info when available
    if (oktaAuth) {
      // get user info from okta and then persist
      getUserNameOkta(oktaAuth).then((info: UserDetailsOkta) => {
        setUser(info);

        //   persist the user info in local storage
        setItem(KEY.USER, info);
      });
    }
  }, [oktaAuth, userInfo, authState?.isAuthenticated, opacity, status]);

  useEffect(() => {
    // store oktaToken
    setToken(oktaAuth.getAccessToken() as string);
  }, [oktaAuth]);

  const valueProps = useMemo(
    () => ({
      user,
      setUser,
      status,
      setStatus,
      opacity,
      setOpacity,
      selectedParty,
      setSelectedFunds,
      selectedFunds,
      setSelectedParty,
      toastInfo,
      setToastInfo,
    }),
    [user, status, opacity, selectedParty, selectedFunds, toastInfo],
  );

  return (
    <AppContext.Provider
      value={{
        user,
        setUser,
        status,
        setStatus,
        opacity,
        setOpacity,
        selectedParty,
        setSelectedFunds,
        selectedFunds,
        setSelectedParty,
        toastInfo,
        setToastInfo,
        token,
        setToken,
      }}
    >
      {children}
      <Toast />
    </AppContext.Provider>
  );
};
