import { FC, useState, useEffect } from "react";
import {
  Container,
  Title,
  SubHeader,
  SubHeaderNav,
  SubContent,
} from "styles/party/funds/ManageFunds.styled";

import { FundDocumentsType, FundType } from "types/funds";
import useOktaAccessToken from "hooks/useOktaHooks";
import { useParams } from "react-router-dom";
import { getSubFundById, listFundsDocuments, getSubFunds } from "services/funds";
import Close from "@material-ui/icons/Close";
import { useAppData } from "hooks/useAppData";
import LoadingSpinner from "components/LoadingSpinner";
import { LoadingSpinnerDiv } from "styles/LoadingSpinner.styled";
import { ActivityType, useTrackActivity } from "hooks/useTracker";
import { FilterableTable } from "components/filterableTable";
import { TRACKER } from "constants/tracker";
import { ColumnDetails, ItemsPortfolioBreakdown, SingleFund } from "../../../../types";
import Funds from "../index";

const getSubFundsByChildID = (subFunds: SingleFund[], childFundsIds: string) => {
  const fundIds = childFundsIds.split(",");
  const data: FundType[] = [];
  subFunds.forEach((fund: FundType) => {
    if (fundIds.includes(fund["Fund ID"])) {
      data.push(fund);
    }
  });
  return data;
};

const SubFunds: FC<{
  childFundIds: string;
  filter: string;
}> = ({ childFundIds, filter }) => {
  const [isSub, setSub] = useState(false);
  const { colors } = useAppData();
  const [fundData, setFundData] = useState<ItemsPortfolioBreakdown | null>(null);
  const [_, setLoading] = useState(false);
  const [subFundsData, setSubFundsData] = useState<FundType[]>([]);
  const [fundDocuments, setFundDocuments] = useState<FundDocumentsType | null>(null);
  const [selectedIndex, setSelectedIndex] = useState("");
  const accessToken = useOktaAccessToken();
  const { id } = useParams<{ id: string }>();
  const close = () => {
    setSub(false);
    setSelectedIndex("");
  };
  const [limit] = useState(40);
  const [currentPage] = useState<number>(1);
  const viewedPagesLength = currentPage * limit;
  const { track } = useTrackActivity();

  const displayableData = subFundsData.slice(viewedPagesLength - limit, viewedPagesLength);

  const onClickRow = (rowId: string) => {
    if (selectedIndex === rowId) {
      close();
    } else {
      setSelectedIndex(rowId);
      setSub(true);
      const clickedItem = displayableData.find((item) => item["Fund ID"] === id);
      track(ActivityType.CLICK, TRACKER.PARTY_PROFILE_SUB_FUNDS, {
        activity_info: TRACKER.SUB_FUNDS_CLICKED,
        sub_fund_clicked: clickedItem!["Fund Name"],
      });
    }
  };
  useEffect(() => {
    if (accessToken && id && selectedIndex) {
      listFundsDocuments(accessToken, selectedIndex).then((d) => {
        setFundDocuments(d);
      });
      getSubFundById(accessToken, id, selectedIndex).then((d) => {
        setFundData(d);
      });
    }
  }, [accessToken, id, selectedIndex]);

  useEffect(() => {
    if (accessToken && id) {
      setLoading(true);
      getSubFunds(accessToken, id)
        .then((d) => {
          setSubFundsData(getSubFundsByChildID(d, childFundIds));
        })
        .finally(() => {});
    }
  }, [accessToken, id]);

  return (
    <Container data-testid="sub-funds">
      {!isSub ? (
        displayableData.length ? (
          <FilterableTable
            data={displayableData as unknown as ColumnDetails[]}
            onClickRow={onClickRow}
            idKey="Fund ID"
            tableType="FP"
            filter={filter}
          />
        ) : null
      ) : fundData ? (
        <SubHeader>
          <SubHeaderNav>
            <Title>{fundData?.["Fund Name"]}</Title>
            <Close onClick={close} style={{ cursor: "pointer" }} htmlColor={colors.PRIMARY[400]} />
          </SubHeaderNav>
          <SubContent maxHeight={window.innerHeight < 960 ? "38vh" : "44vh"}>
            <Funds data={fundData} fundDocuments={fundDocuments!} hideSubFund />
          </SubContent>
        </SubHeader>
      ) : (
        <LoadingSpinnerDiv>
          <LoadingSpinner />
        </LoadingSpinnerDiv>
      )}
    </Container>
  );
};

export default SubFunds;
