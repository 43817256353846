import { MAIN_CACHE_BUCKET_NAME } from "config/googleCloudConfig";
import { getFromGoogleBucket } from "./googleCloudStorage";
import { getFederatedToken } from "./okta/federatedAccessToken";

export const getSSI = async (accessToken: string, id: string) => {
  const federatedToken = await getFederatedToken(accessToken);

  if (federatedToken) {
    const ssi = await getFromGoogleBucket(
      MAIN_CACHE_BUCKET_NAME,
      "",
      `SSI/${id}.json`,
      federatedToken,
    );
    const ssiData = JSON.parse(new TextDecoder().decode(ssi).toString());
    return ssiData;
  }

  return null;
};
