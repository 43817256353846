import { useState, FunctionComponent } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { ActivityType, trackActivity } from "hooks/useTracker";
import { TRACKER } from "constants/tracker";
import { ModalController } from "../../hooks/useModal";
import ModalComponent from "../modal";
import { logout } from "../../services/okta/okta";
import {
  SoModalHouse,
  SoCancelButton,
  SoModalDepictionImage,
  SoModalTitle,
  SoModalMessage,
  SoActionButtonsContainer,
  SoActionButton,
} from "../../styles/SignOut.styled";
import LoadingSpinner from "../LoadingSpinner";

const SignOut: FunctionComponent<{ controller: ModalController }> = ({ controller }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const { oktaAuth } = useOktaAuth();

  const closeModal = () => {
    setLoading(false);
    controller.close();
  };

  const signOutAction = () => {
    setLoading(true);
    logout(oktaAuth);
    setLoading(false);
    controller.close();
    trackActivity(ActivityType.SIGNOUT, TRACKER.SIGNOUT_PAGE);
  };

  return (
    <ModalComponent controller={controller}>
      <SoModalHouse>
        <SoCancelButton onClick={closeModal} />
        <SoModalDepictionImage />
        <SoModalTitle>Sign out from T360</SoModalTitle>
        <SoModalMessage>Are you sure you want to sign out from your T360 account?</SoModalMessage>
        <SoActionButtonsContainer>
          <SoActionButton onClick={controller.close}>
            <span>Cancel</span>
          </SoActionButton>
          <SoActionButton onClick={signOutAction}>
            {loading ? <LoadingSpinner /> : <span>Sign Out</span>}
          </SoActionButton>
        </SoActionButtonsContainer>
      </SoModalHouse>
    </ModalComponent>
  );
};

export default SignOut;
