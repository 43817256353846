import useEffectOnce from "hooks/useEffectOnce";
import { ActivityType, useTrackActivity } from "hooks/useTracker";
import { TRACKER } from "constants/tracker";
import { Accordion } from "components/party/accordion/Accordion";
import { FieldName } from "styles/SectionSharedItems.styled";
import { Link } from "react-router-dom";
import { PATH } from "constants/index";
import { useState, useEffect, FunctionComponent } from "react";
import React from "react";
import { format } from "date-fns";
import { CcIdListProps } from "types";
import { 
    Container,
    Table,
    Th,
    Thead,
    Tr,
    Sn,
    Tbody,
    Td,
    PaginationContainer,
    PaginationButton,
    PaginationPosition,
    InnerContent1,
    SpanWrapper,
    EmptySpan,
    TableDate,
    TableTime,
    NoResult,
    Extra,
    ExtraButton,
} from "styles/admin/AdminTable.styled";
import ClientTemplateRequestComponent from "components/funds/ClientRequestTemplate";
import { AdminFundsDashboardDiv } from "styles/funds/index.styled";
import { RequestBy } from "components/party/funds/admin/dashboard/requestBy";
import { ProgressWdgt } from "components/party/funds/admin/dashboard/progressWdgt";
import { RiskRanking } from "components/party/funds/admin/dashboard/riskRanking";
import { Documents } from "components/party/funds/admin/dashboard/documents";
import { Status } from "components/party/funds/admin/dashboard/status";

const CcIdList: FunctionComponent<{data:CcIdListProps[]}> = ({data}) => {
    const { track } = useTrackActivity();   
    const [dataToDisplay, setDataToDisplay] = useState<CcIdListProps[]>([]);
    const [selectedIndex, setSelectedIndex] = useState("");
    const singleData = dataToDisplay.find((el) => el.id === selectedIndex);

    const close = () => {
        setSelectedIndex("");
    };

    const toggleVisibility = (index: string) => {
        if (selectedIndex === index) {
          close();
        } else {
          setSelectedIndex(index);
        }
    };

    useEffect(() => {
        if (selectedIndex) {
          const filteredData = dataToDisplay.filter((data) => data.id === selectedIndex);
          setDataToDisplay(filteredData);
        } else {
          setDataToDisplay(data);
        }
      }, [selectedIndex, data]);
  
    useEffectOnce(() => {
      track(ActivityType.LOAD_PAGE, TRACKER.HUBWISE_LANDING_PAGE);
    });
  
    return (
        <Container>
            {!selectedIndex ? (
                <Table>
                    <Thead>
                    <Tr>
                        <Th>Created</Th>
                        <Th>ID</Th>
                    </Tr>
                    </Thead>
                    <Tbody hide={dataToDisplay?.length === 0 || !dataToDisplay}>
                    {dataToDisplay.map((item, index) => (
                        <React.Fragment key={item.id}>
                                <Tr
                                    body
                                    data-testid="row"
                                >
                                    <Td>
                                        <EmptySpan>
                                            <Sn>{selectedIndex || index + 1}</Sn>
                                            <SpanWrapper>
                                                <TableDate>
                                                    {format(new Date(item.createdAt), "dd MMM yyy")}
                                                    <br />
                                                </TableDate>
                                                <TableTime>{format(new Date(item.createdAt), "HH:mm")}</TableTime>
                                            </SpanWrapper>
                                        </EmptySpan>
                                    </Td>
                                    <Td>
                                        <Accordion title={item.id} small borderBottom={false} noTopBorder noPaddingTop light>
                                            <div
                                                onClick={() => toggleVisibility(item.id)}
                                                >
                                                <AdminFundsDashboardDiv>
                                                    <RequestBy data={item} />
                                                    <ProgressWdgt data={item} />
                                                    <RiskRanking data={item}  />
                                                    <Documents data={item}  />
                                                    <Status data={item} />
                                                </AdminFundsDashboardDiv>
                                            </div>
                                        </Accordion>
                                    </Td>
                                </Tr>
                        </React.Fragment>
                    ))}
                    {!selectedIndex ? (
                        <Tr>
                        <Td colSpan={4}>
                            <PaginationContainer>
                            <PaginationButton>Previous</PaginationButton>
                            <PaginationPosition>Page 1 of 10</PaginationPosition>
                            <PaginationButton>Next</PaginationButton>
                            </PaginationContainer>
                        </Td>
                        </Tr>
                    ) : null}
                    </Tbody>
                </Table>
            ) : null }
            {selectedIndex === singleData?.id && (
                <div>
                    <Extra data-testid="extra-data">
                        <div>
                            <InnerContent1>
                                <ExtraButton onClick={close}>Close</ExtraButton>
                            </InnerContent1>
                        </div>
                    </Extra>
                    <ClientTemplateRequestComponent />
                </div>                
            )}
            <NoResult show={dataToDisplay?.length === 0 || !dataToDisplay}>
                No record found to display
            </NoResult>
        </Container>
    );
  }
  export default CcIdList;