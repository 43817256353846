import useEffectOnce from "hooks/useEffectOnce";
import { ActivityType, useTrackActivity } from "hooks/useTracker";
import { TRACKER } from "constants/tracker";
import SSnCLandingPage from "components/ssncLandingPage/SsncLandingPage";

function HubwiseLandingPage() {
    const { track } = useTrackActivity();
  
    useEffectOnce(() => {
      track(ActivityType.LOAD_PAGE, TRACKER.HUBWISE_LANDING_PAGE);
    });
  
    return (
      <div className="HubwiseLandingPage">
        <SSnCLandingPage />
      </div>
    );
  }
  export default HubwiseLandingPage;