import { DiDoMenuItem, InteractiveDataItem } from "../types";

const interactiveIcon = `${process.env.PUBLIC_URL}/assets/interactive-icon.svg`;
const clientIcon = `${process.env.PUBLIC_URL}/assets/client-admin-room-icon.svg`;
const settingIcon = `${process.env.PUBLIC_URL}/assets/settings-dido-icon.svg`;

export const interactiveData: InteractiveDataItem[] = [
  {
    name: "HSBC",
    iconURL: `${process.env.PUBLIC_URL}/assets/1-interactive.svg`,
    prototypeURL: "",
  },
  {
    name: "Deutsche Bank",
    iconURL: `${process.env.PUBLIC_URL}/assets/2-interactive.svg`,
    prototypeURL:
      "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FX8Yh136kvG6xC0hSIsagf0%2FFullDemo-Development%3Fnode-id%3D1971%253A129644%26scaling%3Dmin-zoom%26page-id%3D0%253A1%26starting-point-node-id%3D1971%253A129644",
  },
  {
    name: "Barclays",
    iconURL: `${process.env.PUBLIC_URL}/assets/3-interactive.svg`,
    prototypeURL: "",
  },
  {
    name: "Wells Fargo",
    iconURL: `${process.env.PUBLIC_URL}/assets/4-interactive.svg`,
    prototypeURL: "",
  },
  {
    name: "UBS",
    iconURL: `${process.env.PUBLIC_URL}/assets/5-interactive.svg`,
    prototypeURL: "",
  },
  {
    name: "BBVA",
    iconURL: `${process.env.PUBLIC_URL}/assets/6-interactive.svg`,
    prototypeURL: "",
  },
  {
    name: "Goldman Sachs",
    iconURL: `${process.env.PUBLIC_URL}/assets/7-interactive.svg`,
    prototypeURL: "",
  },
  {
    name: "BNY Mellon",
    iconURL: `${process.env.PUBLIC_URL}/assets/8-interactive.svg`,
    prototypeURL: "",
  },
  {
    name: "Santander",
    iconURL: `${process.env.PUBLIC_URL}/assets/9-interactive.svg`,
    prototypeURL: "",
  },
  {
    name: "J.P.Morgan",
    iconURL: `${process.env.PUBLIC_URL}/assets/10-interactive.svg`,
    prototypeURL: "",
  },
  {
    name: "Credit Suisse",
    iconURL: `${process.env.PUBLIC_URL}/assets/11-interactive.svg`,
    prototypeURL: "",
  },
  {
    name: "Morgan Stanley",
    iconURL: `${process.env.PUBLIC_URL}/assets/12-interactive.svg`,
    prototypeURL: "",
  },
];

export const DIDO_DEMO_MENU_ITEMS: DiDoMenuItem[] = [
  {
    text: "Interactive Demo’s",
    icon: interactiveIcon,
    link: "interactive",
  },
  {
    text: "Client Admin Room",
    icon: clientIcon,
    link: "client-admin",
  },
  {
    text: "Settings",
    icon: settingIcon,
    link: "settings",
  },
];
