import { Routes, Route } from "react-router-dom";
import { LoginCallback } from "@okta/okta-react";
import Party from "pages/party";
import { useAppData } from "hooks/useAppData";
import { ClientAdminTab, InteractiveTab } from "components/didoDemo/RightContent";
import FundsSearch from "pages/funds/Search";
import Funds from "pages/funds";
import { PARTY } from "constants/index";
import { HeaderType } from "types";
import Header from "components/header/Header";
import { Main } from "styles/App.styled";
import { FundsSearchResults } from "pages/funds/FundsSearchResults";
import FundHeader from "components/header/FundHeader";
import Home from "../pages/home/Home";
import Protected from "./Protected";
import LoadingSpinner from "../components/LoadingSpinner";
import { SearchResults } from "../pages/searchResults/SearchResults";
import SearchPage from "../pages/search/Search";
import Alert from "../pages/alert/Alert";
import AlertAirtableTesting from "../pages/alertAirtableTesting/AlertAirtableTesting";
import DiDoDemo from "../pages/didoDemo";
import AdminRoom from "../pages/admin";
import ClientRoom from "../pages/client";
import AliceRequests from "../pages/admin/AliceRequests";
import ClientAliceRequests from "../pages/client/AliceRequests";
import Report from "../pages/client/Report";
import Connect from "../pages/client/Connect";
import EntityLink from "../pages/admin/EntityLink";
import GetSearch from "../pages/admin/Search";
import Dashboard from "../pages/admin/Dashboard";
import Settings from "../pages/admin/Settings";
import Ecp from "../pages/ecp/Ecp";
import Reports from "../pages/admin/Reports";
import HubwiseDashboard from "pages/hubwiseDashboard/HubwiseDashboard";
import HubwiseProfiles from "pages/hubwiseDashboard/HubwiseProfiles";
import HubwiseRequests from "pages/hubwiseDashboard/HubwiseRequests";
import CCIDProfile from "../pages/ccIdProfile/index"
import HubwiseClientRequestTemplate from "pages/hubwiseDashboard/HubwiseClientRequestTemplate";

function AppRoutes() {
  const { isDidoDemo, isUserPartOfOrganization, isUserAuthenticated, isT360Email } = useAppData();

  return (
    <Main>
      {isUserPartOfOrganization([PARTY.HUBWISE]) ? (
        <FundHeader isUserAuthenticated={isUserAuthenticated} headerStyle={HeaderType.FUNDS} />
      ) : (
        <Header
          headerStyle={HeaderType.DEFAULT}
          isT360Email={isT360Email!}
          isUserAuthenticated={isUserAuthenticated}
        />
      )}
      <Routes>
        <Route
          path="login/callback"
          element={<LoginCallback loadingElement={<LoadingSpinner />} />}
        />
          <Route path="">
            <Route path="/" element={<Home />} />
            {/* hubwise dashboard */}
            <Route path="/dashboard" element={<HubwiseDashboard />}>
              <Route path="requests" element={<HubwiseRequests />} />
              <Route path="profiles" element={<HubwiseProfiles />} />
            </Route>
          </Route>

          <Route
            path=""
            element={<Protected no_access={isUserPartOfOrganization([PARTY.HUBWISE])} />}
          >
            <Route path="/search" element={<SearchPage />} />
            <Route path="/search-results/:targetEntityId" element={<SearchResults />} />
            <Route path="/alert" element={<Alert />} />
            <Route path="/checkmate-testing" element={<AlertAirtableTesting />} />
            <Route path="/party/:id" element={<Party />} />
            <Route path="/ECP-test" element={<Ecp />} />
            <Route path="/ccIdProfile/:id" element={<CCIDProfile />} />
          </Route>

          {/* funds routes */}
          <Route path="/funds/search" element={<FundsSearch />} />
          <Route path="/funds/:id" element={<Funds />} />
          <Route path="/funds/search-results/:targetEntityId" element={<FundsSearchResults />} />
          <Route path="/funds/crt" element={<HubwiseClientRequestTemplate />} />


          {/* admin room */}
          <Route path="/" element={<Protected no_access={!isT360Email} />}>
            <Route path="admin" element={<AdminRoom />}>
              <Route path="alice-requests" element={<AliceRequests />} />
              <Route path="entity-link" element={<EntityLink />} />
              <Route path="get-search" element={<GetSearch />} />
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="settings" element={<Settings />} />
              <Route path="reports" element={<Reports />} />
              <Route path="*" element={null} />
            </Route>
          </Route>

          {/* client room */}
          <Route path="/" element={<Protected no_access={isT360Email!} />}>
            <Route path="client" element={<ClientRoom />}>
              <Route path="alice-requests" element={<ClientAliceRequests />} />
              <Route path="connect" element={<Connect />} />
              <Route path="report" element={<Report />} />
              <Route path="settings" element={<Settings />} />
              <Route path="*" element={null} />
            </Route>
          </Route>

          {/* didodemo room */}
          <Route path="/" element={<Protected no_access={!isDidoDemo} />}>
            <Route path="dido-demo" element={<DiDoDemo />}>
              <Route path="interactive" element={<InteractiveTab />} />
              <Route path="client-admin" element={<ClientAdminTab />} />
              <Route path="settings" element={<Settings />} />
              <Route path="*" element={null} />
            </Route>
          </Route>
      </Routes>
    </Main>
  );
}

export default AppRoutes;
