import _ from "lodash";
import {
  EntityData,
  FinancialGroupItem,
  Identifiers,
  NameValue,
  ObjectType,
  Result,
  TotalAssetsWithClosingDate,
} from "../types";

// we use this utility function to have all data as Object.entries (same 'format')
const getEntries = (entityData: EntityData[]): [string, unknown][] => {
  if (Array.isArray(entityData)) {
    return Object.entries(entityData);
  }
  // so if it's not an array, create a 1-element array
  const tmpArray: EntityData[] = [];
  tmpArray.push(entityData);
  return Object.entries(tmpArray);
};

const getTotalAssetsWithClosingDate = (
  mapEntryClosingDate: string,
  mapEntryTotalAssets: string,
  highestTotalAssets: TotalAssetsWithClosingDate,
): TotalAssetsWithClosingDate => {
  // check if we have a closing date
  if (mapEntryClosingDate && mapEntryClosingDate.trim() !== "") {
    /*
     * So far we noticed that PartyDetails has 2 kind of date format strings:
     * 1) mm/dd/yyyy
     * 2) yyyymmdd
     * It means that we need to convert the formats into a UTC date format (year, month - 1, day)
     */

    const tmpClosingDate: Date = mapEntryClosingDate.includes("/")
      ? new Date(
          Date.UTC(
            parseInt(mapEntryClosingDate.substring(6, 10), 10),
            parseInt(mapEntryClosingDate.substring(0, 2), 10) - 1,
            parseInt(mapEntryClosingDate.substring(3, 5), 10),
          ),
        )
      : new Date(
          Date.UTC(
            parseInt(mapEntryClosingDate.substring(0, 4), 10),
            parseInt(mapEntryClosingDate.substring(4, 6), 10) - 1,
            parseInt(mapEntryClosingDate.substring(6, 8), 10),
          ),
        );

    // ensure that we have total assets data
    if (mapEntryTotalAssets && mapEntryTotalAssets.trim() !== "") {
      // try to take the most recent one
      if (tmpClosingDate.getTime() > highestTotalAssets.closingDate.getTime()) {
        highestTotalAssets.totalAssetsValue = parseInt(mapEntryTotalAssets.replace(/,/g, ""), 10);
        highestTotalAssets.closingDate = tmpClosingDate;
      } else if (
        tmpClosingDate.getTime() === highestTotalAssets.closingDate.getTime() &&
        parseInt(mapEntryTotalAssets, 10) > highestTotalAssets.totalAssetsValue
      ) {
        // and if they have the same date, consider the one with highest totalAssets
        highestTotalAssets.totalAssetsValue = parseInt(mapEntryTotalAssets.replace(/,/g, ""), 10);
      }
    }
  }

  return highestTotalAssets;
};

// function for retrieving the totalAssets (necessary for ordering search results) from the Party Details
const getTotalAssets = (mapData: Map<string, NameValue>[]): number => {
  if (mapData.length === 0) {
    return 0;
  }

  let highestU1TotalAssets: TotalAssetsWithClosingDate = {
    totalAssetsValue: 0,
    closingDate: new Date(Date.UTC(1970, 0, 1)),
  };
  let highestOtherTotalAssets: TotalAssetsWithClosingDate = {
    totalAssetsValue: 0,
    closingDate: new Date(Date.UTC(1970, 0, 1)),
  };

  mapData.forEach((mapItem) => {
    mapItem.forEach((mapEntry) => {
      // first of all check the U1 consolidation code values
      if (mapEntry["Consolidation code"] && mapEntry["Consolidation code"] === "U1") {
        highestU1TotalAssets = getTotalAssetsWithClosingDate(
          mapEntry["Closing date"],
          mapEntry["Total assets"],
          highestU1TotalAssets,
        );

        // if we do not have any U1 consolidation codes to consider, let's find out the most recent totalAssets info across all the other consolidation codes
      } else if (mapEntry["Consolidation code"] && mapEntry["Consolidation code"] !== "U1") {
        highestOtherTotalAssets = getTotalAssetsWithClosingDate(
          mapEntry["Closing date"],
          mapEntry["Total assets"],
          highestOtherTotalAssets,
        );
      }
    });
  });

  return highestU1TotalAssets.totalAssetsValue > 0
    ? highestU1TotalAssets.totalAssetsValue
    : highestOtherTotalAssets.totalAssetsValue;
};

export function setResult(
  financialGroupItem: FinancialGroupItem,
  financialGroupGuoId: string,
  targetEntityId: string,
): Result {
  const rawEntityData: ObjectType = financialGroupItem.previewData;
  const currentFinancialGroupItemId: string = financialGroupItem.entityId;

  /**
   * In the following lines we are using a general - but still to be verified - rule/check:
   * 1) first of all check if we have Banks_only data (BO) about that specific entity
   * 2) if not, get the data from Not Banks_only data (NO_BO)
   */

  const getData = (key: string) => {
    return Object.prototype.hasOwnProperty.call(rawEntityData, key);
  };

  const nameMap = getData("Banks_only/BvD_ID_and_Name.txt")
    ? new Map(Object.entries(rawEntityData["Banks_only/BvD_ID_and_Name.txt"]))
    : (getData("Name") && new Map(Object.entries(rawEntityData.Name))) || undefined;

  const overviewsMap = getData("Banks_only/Overviews.txt")
    ? new Map(Object.entries(rawEntityData["Banks_only/Overviews.txt"]))
    : (getData("Overviews.txt") && new Map(Object.entries(rawEntityData["Overviews.txt"]))) ||
      undefined;

  const legalInfoMap = getData("Banks_only/Legal_info.txt")
    ? new Map(Object.entries(rawEntityData["Banks_only/Legal_info.txt"]))
    : (Object.prototype.hasOwnProperty.call(rawEntityData, "Legal_info.txt") &&
        new Map(Object.entries(rawEntityData["Legal_info.txt"]))) ||
      undefined;

  const contactInfoMap = Object.prototype.hasOwnProperty.call(
    rawEntityData,
    "Banks_only/Contact_info.txt",
  )
    ? new Map(Object.entries(rawEntityData["Banks_only/Contact_info.txt"]))
    : (getData("Contact_info.txt") && new Map(Object.entries(rawEntityData["Contact_info.txt"]))) ||
      undefined;

  const identifiersArray = getData("Banks_only/Identifiers.txt")
    ? Object?.entries(rawEntityData["Banks_only/Identifiers.txt"])
    : getData("Identifiers.txt")
    ? Object?.entries(rawEntityData["Identifiers.txt"])
    : [];

  // EntitiesWithArchive is not in BO folder, so we do not need to do the check
  const entitiesMap = getData("EntitiesWithArchive.txt")
    ? new Map(Object.entries(rawEntityData["EntitiesWithArchive.txt"]))
    : undefined;

  const identifiersMapsArray: Map<string, string>[] = [];

  if (identifiersArray?.length) {
    identifiersArray?.forEach((row) => {
      identifiersMapsArray.push(new Map<string, string>(Object.entries(row[1] as [string])));
    });
  }

  const identifiers: Array<Array<string>> = [];
  identifiersMapsArray.forEach((row) => {
    identifiers.push([
      row?.get("National ID label") as string,
      row?.get("National ID number") as string,
    ]);
  });

  /*
   * Now we have to extract the U1 totalAssets (if possible) or the highest totalAssets value from 3 different files (coming from BO and NO_BO)
   */

  const dataForFindTotalAssets: any[] = [];

  if (getData("Banks_only/Key_financials-USD.txt")) {
    dataForFindTotalAssets.push(
      new Map(getEntries(rawEntityData["Banks_only/Key_financials-USD.txt"])),
    );
  }

  if (getData("Key_financials-USD.txt")) {
    dataForFindTotalAssets.push(new Map(getEntries(rawEntityData["Key_financials-USD.txt"])));
  }

  if (getData("Banks_only/Industry-Global_financials_and_ratios-USD.txt")) {
    dataForFindTotalAssets.push(
      new Map(
        getEntries(rawEntityData["Banks_only/Industry-Global_financials_and_ratios-USD.txt"]),
      ),
    );
  }

  if (getData("Industry-Global_financials_and_ratios-USD.txt")) {
    dataForFindTotalAssets.push(
      new Map(getEntries(rawEntityData["Industry-Global_financials_and_ratios-USD.txt"])),
    );
  }

  if (getData("Banks_only/Insurances-Global_financials_and_ratios-USD.txt")) {
    dataForFindTotalAssets.push(
      new Map(
        getEntries(rawEntityData["Banks_only/Insurances-Global_financials_and_ratios-USD.txt"]),
      ),
    );
  }

  if (getData("Insurances-Global_financials_and_ratios-USD.txt")) {
    dataForFindTotalAssets.push(
      new Map(getEntries(rawEntityData["Insurances-Global_financials_and_ratios-USD.txt"])),
    );
  }

  const category = (
    legalInfoMap ? legalInfoMap.get("Category of the company") : undefined
  ) as string;
  const country = (contactInfoMap ? contactInfoMap.get("Country") : undefined) as string;
  const description = (overviewsMap ? overviewsMap.get("Full overview") : undefined) as string;
  const history = (overviewsMap ? overviewsMap.get("History") : undefined) as string;
  const entityType = (legalInfoMap ? legalInfoMap.get("Type of entity") : undefined) as string;
  const entityTypeInternal = (entitiesMap ? entitiesMap.get("Entity type") : undefined) as string;
  const internationalName = (
    contactInfoMap
      ? contactInfoMap.get("NAME_INTERNAT")
      : nameMap?.get("NAME")
      ? nameMap?.get("NAME")
      : undefined
  ) as string;
  const entitiesWithArchiveName = (entitiesMap ? entitiesMap.get("Name") : undefined) as string;
  const iso = (entitiesMap ? entitiesMap.get("Country ISO code") : undefined) as string;
  const mainActivity = (overviewsMap ? overviewsMap.get("Main activity") : undefined) as string;

  return {
    category,
    country,
    description,
    history,
    entityType,
    entityTypeInternal,
    identifiers,
    name: internationalName || entitiesWithArchiveName,
    iso,
    mainActivity,
    id: currentFinancialGroupItemId,
    isGuo: currentFinancialGroupItemId === financialGroupGuoId,
    isTargetEntity: currentFinancialGroupItemId === targetEntityId,
    totalAssets: getTotalAssets(dataForFindTotalAssets),
  };
}

// utility function that tells us if the result has empty values by comparing it with an 'empty object'
export const isResultEmpty = (result: Result): boolean => {
  const emptyResultObject: Result = {
    id: result.id,
    mainActivity: undefined,
    category: undefined,
    identifiers: [],
    description: undefined,
    history: undefined,
    name: undefined,
    iso: undefined,
    country: undefined,
    entityType: undefined,
    entityTypeInternal: undefined,
    isGuo: result.isGuo,
    isTargetEntity: result.isTargetEntity,
    totalAssets: result.totalAssets,
  };

  // use lodash to determine if the the two objects are the same (stringify comparison is not strong enough)
  return _.isEqual(result, emptyResultObject);
};
