import { Container } from "styles/party/index.styled";
import ClientTemplateRequestComponent from "components/funds/ClientRequestTemplate";

const HubwiseClientRequestTemplate = () => {
 
  return (
    <Container>
      <div className="container">
        <ClientTemplateRequestComponent />
      </div>
    </Container>
  );
};

export default HubwiseClientRequestTemplate;
