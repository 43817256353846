/* Google Cloud buckets names */
export const ASPECT_FILES_PATH = "old";
export const ASPECT_FILES_SOURCE_BUCKET_NAME = "data-repository-development";
export const FINANCIAL_GROUP_CACHE_DESTINATION_BUCKET_NAME = "preview-cache-development";
export const MAIN_CACHE_BUCKET_NAME = "main-cache-development";
export const VALET_PARKING_RULES_BUCKET_NAME = "valet-rules-development";
export const FUNDS_DATA_REPOSITORY_BUCKET_NAME = "transactional-data-repository-development";


/* Google Cloud Workload */
export const WORKLOAD_READ_SERVICE_ACCOUNT = "workload-read-only-on-a-bucket@development-353213.iam.gserviceaccount.com";
export const WORKLOAD_AUDIENCE = "//iam.googleapis.com/projects/211101543011/locations/global/workloadIdentityPools/okta-development-pool/providers/okta-development-provider";

/* Google Cloud file path names */
export const VALET_PARKING_RULES_PATH = "rules.json"
export const FUNDS_PATH = "funds/v1/funds";
export const SUB_FUNDS_PATH = "funds/v1/sub-funds";
export const FUNDS_DOCUMENTS_PATH = "funds/v1/funds-documents";
