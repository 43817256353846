import { GeneralDetails } from "components/party/GeneralDetails";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Banner, Container } from "styles/party/index.styled";
import { getDataAndMetadata } from "utils/getDataAndMetaData";
import { useAppData } from "hooks/useAppData";

import { ManagedFunds } from "components/party/funds/ManagedFunds";
import LoadingSpinner from "components/LoadingSpinner";
import { GeneralDetailsType, PartyData } from "types/party";
import { useToast } from "hooks/useToast";

const CCIDProfile = () => {
  const { id } = useParams<{ id: string }>();
  const [profileDetails, setProfileDetails] = useState<PartyData>();
  const [_, setUserName] = useState<string>("");
  const { toast } = useToast();
  const { token } = useAppData();

  const { user, selectedParty: generalDetails, setSelectedParty } = useAppData();

  useEffect(() => {
    if (token && id) {
      getDataAndMetadata(token, id)
        .then((data) => {
          if (data) {
            // get general details from data and set
            setSelectedParty({ ...(data?.["General Details"] as GeneralDetailsType), id });

            // set the rest of the data as profileDetails
            const details = { ...data };

            delete details?.["General Details"];

            setProfileDetails(details);
            setUserName(user!.name);
          }
        })
        .catch((err: Error) => toast(err.toString()));
    }
  }, [token, id]);


  if (!profileDetails) return <LoadingSpinner />;
  return (
    <Container>
      <Banner className="container">
        <GeneralDetails data={generalDetails as GeneralDetailsType} />
      </Banner>
      {/* content */}
      <div className="container">
          <ManagedFunds token={token} partyId={id!} data={profileDetails} />
      </div>
    </Container>
  );
};

export default CCIDProfile;
