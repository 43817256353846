import {
  FUNDS_DATA_REPOSITORY_BUCKET_NAME,
  FUNDS_DOCUMENTS_PATH,
  FUNDS_PATH,
  SUB_FUNDS_PATH,
} from "config/googleCloudConfig";
import { INDEX_PATH } from "config/mainConfig";
import { getFromGoogleBucket, listContentFromGoogleBucket } from "./googleCloudStorage";
import { getFederatedToken } from "./okta/federatedAccessToken";

export const getAllFunds = async (accessToken: string, id: string) => {
  const federatedToken = await getFederatedToken(accessToken);

  if (federatedToken) {
    const rowFundsIndex = await getFromGoogleBucket(
      FUNDS_DATA_REPOSITORY_BUCKET_NAME,
      "",
      `${FUNDS_PATH}/${id}/${INDEX_PATH}`,
      federatedToken,
    );
    const fundsIndex = JSON.parse(new TextDecoder().decode(rowFundsIndex).toString());

    return fundsIndex;
  }
};

export const getFundsById = async (accessToken: string, id: string, fundId: string) => {
  const federatedToken = await getFederatedToken(accessToken);

  if (federatedToken) {
    const rowFund = await getFromGoogleBucket(
      FUNDS_DATA_REPOSITORY_BUCKET_NAME,
      "",
      `${FUNDS_PATH}/${id}/${fundId}.json`,
      federatedToken,
    );
    const fund = JSON.parse(new TextDecoder().decode(rowFund).toString());
    return fund;
  }
};

export const getFundsDocument = async (accessToken: string) => {
  const federatedToken = await getFederatedToken(accessToken);

  if (federatedToken) {
    const rowFundDocument = await getFromGoogleBucket(
      FUNDS_DATA_REPOSITORY_BUCKET_NAME,
      "",
      `${FUNDS_DOCUMENTS_PATH}/0A59/index.json`,
      federatedToken,
    );
    const fundDocument = JSON.parse(new TextDecoder().decode(rowFundDocument).toString());
    return fundDocument;
  }
};

export const listFundsDocuments = async (accessToken: string, fundId: string) => {
  const federatedToken = await getFederatedToken(accessToken);

  if (federatedToken) {
    const rowFundDocument = await listContentFromGoogleBucket(
      FUNDS_DATA_REPOSITORY_BUCKET_NAME,
      `${FUNDS_DOCUMENTS_PATH}/${fundId}`,
      federatedToken,
    );
    const fundDocument = JSON.parse(new TextDecoder().decode(rowFundDocument).toString());
    return fundDocument;
  }
};

export const getSubFunds = async (accessToken: string, id: string) => {
  const federatedToken = await getFederatedToken(accessToken);

  if (federatedToken) {
    const rowSubFund = await getFromGoogleBucket(
      FUNDS_DATA_REPOSITORY_BUCKET_NAME,
      "",
      `${SUB_FUNDS_PATH}/${id}/${INDEX_PATH}`,
      federatedToken,
    );
    const subFund = JSON.parse(new TextDecoder().decode(rowSubFund).toString());
    return subFund;
  }
};

export const getSubFundById = async (accessToken: string, id: string, fundId: string) => {
  const federatedToken = await getFederatedToken(accessToken);

  if (federatedToken) {
    const rowSubFundById = await getFromGoogleBucket(
      FUNDS_DATA_REPOSITORY_BUCKET_NAME,
      "",
      `${SUB_FUNDS_PATH}/${id}/${fundId}.json`,
      federatedToken,
    );
    const subFundById = JSON.parse(new TextDecoder().decode(rowSubFundById).toString());
    return subFundById;
  }
};
