import { fetchData } from "./fetchData";

/**
 * Download an object from a Google Cloud Storage Bucket.
 * @param {string} bucketName the name of the Google Cloud Storage bucket
 * @param {string} path Google Cloud Storage object complete pathname (e.g. `folder1/folder2/objectName.format`)
 * @param {string} accessToken Google OAuth 2.0 access token
 */
export const getFromGoogleBucket = async (
  bucketName: string,
  path: string,
  fileName: string,
  accessToken: string,
): Promise<ArrayBuffer> => {
  // if there is a path, add '/', if not show empty string
  const Path = path ? `${path}/` : "";

  const url = `https://storage.googleapis.com/storage/v1/b/${bucketName}/o/${Path.replace(
    /\//g,
    "%2F",
  )}${fileName.replace(/\//g, "%2F")}?alt=media`;
  if (accessToken) {
    return fetchData(url, {
      Authorization: `Bearer ${accessToken}`,
      Accept: "application/json",
    });
  }
  throw Error("You need a Google IAM token in order to access this resource");
};

/**
 * Download an object from a Google Cloud Storage Bucket.
 * @param {string} bucketName the name of the Google Cloud Storage bucket
 * @param {string} path Google Cloud Storage object complete pathname (e.g. `folder1/folder2/objectName.format`)
 * @param {string} accessToken Google OAuth 2.0 access token
 */
export const listContentFromGoogleBucket = async (
  bucketName: string,
  path: string,
  accessToken: string,
): Promise<ArrayBuffer> => {
  const url = `https://storage.googleapis.com/storage/v1/b/${bucketName}/o?prefix=${path}`;
  if (accessToken) {
    return fetchData(url, {
      Authorization: `Bearer ${accessToken}`,
      Accept: "application/json",
    });
  }
  throw Error("You need a Google IAM token in order to access this resource");
};
