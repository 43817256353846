import { HubwiseDashboardMenuItem } from "types";

const requestsIcon = `${process.env.PUBLIC_URL}/assets/ticket.svg`;
const profilesIcon = `${process.env.PUBLIC_URL}/assets/openPartyProfileIcon.svg`;

export const HUBWISE_DASHBOARD_MENU_ITEMS: HubwiseDashboardMenuItem[] = [
    {
        text: "Client Requests Template",
        icon: requestsIcon,
        link: "requests"
    },
    {
        text: "Profiles",
        icon: profilesIcon,
        link: "profiles"
    },
];