import {
    Item,
    FieldItem,
    FieldListItem,
    List,
    Container,
  } from "styles/party/Cash.styled";
  import data from "data/fundRequestTemplateData.json";
  import hubwiseTemplateData from "data/hubwiseFundDataRequestTemplate.json";
  import { useParams } from "react-router-dom";
  import { ActivityType, useTrackActivity } from "hooks/useTracker";
  import useEffectOnce from "hooks/useEffectOnce";
  import { TRACKER } from "constants/tracker";
  import { ContainerFunds, HubwiseContainerFunds, Field, Value } from "styles/funds/ClientTemplateRequest.styled";
  
  function ClientTemplateRequestComponent () {
    const { id } = useParams();
  
    const { track } = useTrackActivity();
  
    useEffectOnce(() => {
      track(ActivityType.LOAD_PAGE, TRACKER.CLIENT_REQUEST_TEMPLATE, {
      });
    });
  
    return (
      <Container>
        {/* 12 fields */}
          <ContainerFunds>
            <h3 key="ccid-level-holder">Client Channel ID</h3>
            {data.map((item, index) => (
              <Item key={index}>
                <Value>{item.name}</Value>
                <Field legal={item.name === "Full Legal Name"} isArray={Array.isArray(item.fields)}>
                  {Array.isArray(item.fields) ? (
                    item.fields.map((field, index) => (
                      <FieldListItem key={index}>
                        {typeof field === "string"
                          ? field
                          : Object.entries(field).map(([title, values], i) => (
                              <List key={i}>
                                <div>{title}</div>
                              </List>
                            ))}
                      </FieldListItem>
                    ))
                  ) : (
                    <FieldItem>{item.fields}</FieldItem>
                  )}
                </Field>
              </Item>
            ))}
          </ContainerFunds>
            {/* 31 fields */}
          <HubwiseContainerFunds>
          <h3 key="fund-level-holder">Fund level</h3>
            {hubwiseTemplateData.map((item, index) => (
              <Item key={index}>
                <Value>{item.name}</Value>
                <Field legal={item.name === "Full Legal Name"} isArray={Array.isArray(item.fields)}>
                  {Array.isArray(item.fields) ? (
                    item.fields.map((field, index) => (
                      <FieldListItem key={index}>
                        {typeof field === "string"
                          ? field
                          : Object.entries(field).map(([title, values], i) => (
                              <List key={i}>
                                <div>{title}</div>
                              </List>
                            ))}
                      </FieldListItem>
                    ))
                  ) : (
                    <FieldItem>{item.fields}</FieldItem>
                  )}
                </Field>
              </Item>
            ))}
        </HubwiseContainerFunds>
      </Container>
    
    );
  }
  
  export default ClientTemplateRequestComponent;
  